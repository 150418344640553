import React from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {useMsal} from "@azure/msal-react";
import {Link} from "react-router-dom";
import moment from "moment-timezone/moment-timezone-utils";
import {Icon, IconButton, Tooltip} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';


const withRouter = WrappedComponent => props => {
    const {instance} = useMsal();
    const {t} = useTranslation('addFlow','viewFlow','common')

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
        />
    );
};

class ViewFlow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}

        if (typeof props === 'undefined') {
            throw new Error("Required prop is undefined")
        }


        let requiredParams = [
            "flowId",
            "calcPlaces",
            "flowName",

            "createdBy",
            "createdTime",
            "modifiedBy",
            "modifiedTime",
            "calibrationCoefficient"
        ];

        let missingParams = [];
        for (let requiredParam of requiredParams) {
            if (typeof props[requiredParam] === 'undefined') {
                missingParams.push(requiredParam);
            }
        }
        if (missingParams.length > 0) {
            throw new Error("Missing required props " + missingParams)
        }

        this.genInfoRow=this.genInfoRow.bind(this);
    }

    genInfoRow(props){
        let {t}=this.props;
        let {name, tooltip, inputValue, valueIfPresent} = props;

        return (
            <dl className={"column col-12"}>
                <div className={"columns"} style={{paddingTop:"5px",borderBottom: "2px solid #D8D8D8"}}>


                            <dt className={"column col-6"} htmlFor={props.name}>{t(name)}


                                {(typeof tooltip!=='undefined' && t(tooltip)!==null && t(tooltip)!=='') &&
                                <Tooltip title={t(tooltip)}>
                                    <IconButton>
                                        <InfoIcon/>

                                    </IconButton>
                                    </Tooltip>
                                }
                            </dt>






                    <dd  className={"column col-6"} id={props.name}>

                        {typeof props.linkTo !== 'undefined' ?
                            <Link to={props.linkTo}>
                            {typeof inputValue === 'undefined'?'': (typeof valueIfPresent!=='undefined'?valueIfPresent:inputValue)}
                            </Link>:
                            typeof inputValue === 'undefined'?'': (typeof valueIfPresent!=='undefined'?valueIfPresent:inputValue)
                        }

                    </dd>

                </div>

        </dl>)
    }

    render() {

        let {t} = this.props;
        let calcPlaces=this.props.calcPlaces.map(place=>{
            return Object.entries(place).map(([k,v])=>{
                if(k==='name') return v
            })
        })

        let {
            flowDirectionType,
            userType,
            flowName,
            calibrationCoefficient,
            flowId,
            createdBy,
            createdTime,
            modifiedBy,
            modifiedTime,
            siteId,
            description,
            counts
            
        } = this.props;

        return (
            <>

                    <div style={{marginTop:"3vh"}} className={"columns"}>
                        <this.genInfoRow  name={'calcPlace_name'}
                                          tooltip={'tooltip_calcPlace'}
                                          inputValue={this.props.calcPlaces} valueIfPresent={(this.props.calcPlaces.some(x=>x.id==siteId) && (this.props.calcPlaces.find(x=>x.id==siteId).id+" "+this.props.calcPlaces.find(x=>x.id==siteId).name))}
                                          linkTo={((this.props.calcPlaces.some(x=>x.id==siteId) && "/sites/"+this.props.calcPlaces.find(x=>x.id==siteId).id))}

                        />
                        <this.genInfoRow  name={'flow_id'}  tooltip={'tooltip_flow_id'}  inputValue={flowId}/>
                        <this.genInfoRow  name={'flow_name'}  tooltip={'tooltip_flow_name'}  inputValue={flowName}/>
                        <this.genInfoRow  name={'flowDirection'}  tooltip={'tooltip_flowDirection'}  inputValue={flowDirectionType}/>
                        <this.genInfoRow  name={'visitor_type'}  tooltip={'tooltip_visitor_type'}  inputValue={userType}/>
                        <this.genInfoRow  name={'calibrationCoefficient_name'}  tooltip={'tooltip_calibrationCoefficient_name'}  inputValue={calibrationCoefficient}/>
                        <this.genInfoRow  name={'flow_description'}  tooltip={'tooltip_flow_desccription'}  inputValue={description}/>

                        <this.genInfoRow  name={'creator_name'}  tooltip={'tooltip_creator_name'}  inputValue={createdBy}/>
                        <this.genInfoRow  name={'created_timestamp'}  tooltip={'tooltip_created_timestamp'}  inputValue={moment(createdTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD.MM.YYYY HH:mm')}/>
                        <this.genInfoRow  name={'changer_name'}  tooltip={'tooltip_changer_name'}  inputValue={modifiedBy}/>
                        <this.genInfoRow  name={'changed_timestamp'}  tooltip={'tooltip_changed_timestamp'}  inputValue={moment(modifiedTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD.MM.YYYY HH:mm')}/>
                        <this.genInfoRow  name={'viewFlow:related_counts'}  tooltip={'viewFlow:tooltip_related_counts'}  inputValue={t("viewFlow:related_counts")+" ("+counts+")"} linkTo={"/count/list/"+flowId}/>

                </div>
            </>
        )
    }

}

export default withRouter(ViewFlow)