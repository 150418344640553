import React from "react";
import {useTranslation} from 'react-i18next';
import {getCodeText} from '../utils/CodeHelpers'
import {Link} from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import {AxiosError} from "axios";
import {GetCodeClasses} from '../utils/NastaApiClient';
import { useSelector, useDispatch, useStore} from 'react-redux';
import * as codesSelector from '../mapViewOl/codesSelector';
import './ViewPlace.css';
import {IconButton, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import UploadingNotification from "../widgets/UploadingNotification/UploadingNotification";

const withRouter = WrappedComponent => props => {
    const {t} = useTranslation('addPlace')
    const codes = useSelector((state) => state.codes);
    const dispatch = useDispatch();
    const storeHelper = useStore();

    return (
        <WrappedComponent
            t={t}
            {...props}
            dispatch={dispatch}
            storeHelper={storeHelper}
            codes={codes}
        />
    );
};

class ViewPlace extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            codeClassesLoaded: false,
            codeClasses: [],
        }
        this.NonMapField=this.NonMapField.bind(this);
        this.genInfoRow=this.genInfoRow.bind(this);
    }

    async componentDidMount() {

        try{
            if(!this.props.storeHelper.getState().codesSelector.codes.codeClassesLoaded){
                let response = await GetCodeClasses();

                if (response instanceof AxiosError) {
                    this.setState({error: response});
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(false));
                } else {
                    this.props.dispatch(codesSelector.fillCodeClasses(response));
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(true));
                }
            }
        }
        catch(error){
            console.log(error);
        }
    }

    genInfoRow(props) {
        let {t} = this.props;
        let {name, tooltip, inputValue, valueIfPresent} = props

        return (
            <dl className={"column col-12"}>
                <div className={"columns"} style={{borderBottom: "2px solid #D8D8D8"}}>
                    <dt className={"column col-6"}>
                        {t(name)}
                        {(typeof tooltip!=='undefined' && t(tooltip)!==null && t(tooltip)!=='') &&
                        <Tooltip title={t(tooltip)}>
                            <IconButton>
                                <InfoIcon/>

                            </IconButton>
                        </Tooltip>
                        }:
                    </dt>

                    <dd className={"column col-6 info-row"}>
                        {typeof props.linkTo !== 'undefined' ? <Link to={props.linkTo}>
                                {typeof inputValue === 'undefined' ? '' : (typeof valueIfPresent !== 'undefined' ? valueIfPresent : inputValue)}
                            </Link> :
                            typeof inputValue === 'undefined' ? '' : (typeof valueIfPresent !== 'undefined' ? valueIfPresent : inputValue)
                        }

                    </dd>

                </div>
            </dl>)
    }

    NonMapField(props){
        let name=props.name;
        let customName=props.customName;

        let { t } = this.props;
        //camelify possible snek case
        const dataName = name =>
            name.toLowerCase().replace(/([-_][a-z])/g, group =>
                group
                    .toUpperCase()
                    .replace('-', '')
                    .replace('_', '')
            );
        return <p>{t(customName??=name)}: {typeof this.props.destination[name] !== 'undefined' ? this.props.destination[dataName]:''}</p>
    }

    handleValue(value, codeType) {
        let resultList = [];
        if (value != null && value.length > 1) {
            value.forEach(num => {
              resultList.push(<div key={num}>{num}  {' ' + getCodeText(codeType, num, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses)}</div>);
            });

          } else {
            if(value != null && value.length === 1)
                resultList.push(<div key={value[0]}>{value[0]} {' ' + getCodeText(codeType, value[0], this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses)}</div>);
          }
      
        return <span>{resultList}</span>;
      }

     render() {
        let { t } = this.props;
        localStorage.setItem("sessionRefresh", JSON.stringify(Date.now()))
        return (<>
                 <UploadingNotification showModal={typeof this.props.destination.destinationName === 'undefined' || this.props.destination.destinationName===''} errors={this.state.errors} />

                <div>
                {typeof this.props.destination !== 'undefined' && <>

                    <this.genInfoRow name={t('input_example_customer_place_id')} tooltip={t('input_tooltip_customer_place_id')} inputValue={typeof this.props.destination.destinationId !== 'undefined' && this.props.destination.destinationId !== null ? this.props.destination.destinationId: ''} />
                    <this.genInfoRow name={t('destination_name')} tooltip={t('tooltip_destination_name')} inputValue={typeof this.props.destination.destinationName !== 'undefined' && this.props.destination.destinationName !== null ? this.props.destination.destinationName: ''} />
                    <this.genInfoRow name={t('destinationType')} tooltip={t('tooltip_destinationType')} inputValue= {typeof this.props.destination.destinationTypeCodeId !== 'undefined' && this.props.destination.destinationTypeCodeId !== null && typeof this.props.destination.destinationTypeClassCodeId !== 'undefined' && this.props.destination.destinationTypeClassCodeId !== null ? this.props.destination.destinationTypeCodeId + ' ' + getCodeText(this.props.destination.destinationTypeClassCodeId, this.props.destination.destinationTypeCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses): ''} />
                    <this.genInfoRow name={t('destinationOwner')} tooltip={t('tooltip_destinationOwner')} inputValue={typeof this.props.destination.destinationOwnerCodeId !== 'undefined' && this.props.destination.destinationOwnerCodeId !== null && typeof this.props.destination.destinationOwnerCodeClassId !== 'undefined' && this.props.destination.destinationOwnerCodeClassId !== null ? this.props.destination.destinationOwnerCodeId + ' ' + getCodeText(this.props.destination.destinationOwnerCodeClassId, this.props.destination.destinationOwnerCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses): ''} />
                    <this.genInfoRow name={t('destinationFunder')} tooltip={t('tooltip_destinationFunder')} inputValue={typeof this.props.destination.destinationFunderCodeId !== 'undefined' && this.props.destination.destinationFunderCodeId !== null && typeof this.props.destination.destinationFunderCodeClassId !== 'undefined' && this.props.destination.destinationFunderCodeClassId !== null ? this.props.destination.destinationFunderCodeId + ' ' + getCodeText(this.props.destination.destinationFunderCodeClassId, this.props.destination.destinationFunderCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses):''} />
                    <this.genInfoRow name={t('destination_description')} tooltip={t('tooltip_destination_description')} inputValue={typeof this.props.destination.description !== 'undefined' && this.props.destination.description !== null ? this.props.destination.description:''} />
                    <this.genInfoRow name={t('inUse')} tooltip={t('tooltip_inUse')} inputValue={typeof this.props.destination.inUse !== 'undefined' && this.props.destination.inUse !== null ? this.props.destination.inUse ? t('inUseResponseTrue') : t('inUseResponseFalse') :''} />
                    <this.genInfoRow name={t('customer_service_point_name')} tooltip={t('tooltip_customer_service_point_name')} inputValue={typeof this.props.destination.customerServiceName !== 'undefined' && this.props.destination.customerServiceName !== null ? this.props.destination.customerServiceName:''} />
                    <this.genInfoRow name={t('customerServiceType')} tooltip={t('tooltip_customerServiceType')} inputValue={typeof this.props.destination.customerServiceTypeCodeId !== 'undefined' && this.props.destination.customerServiceTypeCodeId !== null && typeof this.props.destination.customerServiceTypeCodeClassId !== 'undefined' && this.props.destination.customerServiceTypeCodeClassId !== null ? this.props.destination.customerServiceTypeCodeId + ' ' + getCodeText(this.props.destination.customerServiceTypeCodeClassId, this.props.destination.customerServiceTypeCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses):''} />
                    <this.genInfoRow name={t('land_use_unit_id')} tooltip={t('tooltip_land_use_unit_id')} inputValue={typeof this.props.destination.landUseUnitCodes !== 'undefined' ? this.props.destination.landUseUnitCodes.map(m => m.LandUseUnitId + ' ' + m.LandUseUnitName).join(', ') : '' } />
                    <this.genInfoRow name={t('land_use_unit_type')} tooltip={t('tooltip_land_use_unit_type')} inputValue={typeof this.props.destination.landUseUnitTypeCodes !== 'undefined' ? this.handleValue(this.props.destination.landUseUnitTypeCodes, 1011)  : ''} />
                    <this.genInfoRow name={t('entity')} tooltip={t('tooltip_entity')} inputValue={typeof this.props.destination.entityTypeCodeId !== 'undefined' ? this.props.destination.entityTypeCodeId + ' ' + getCodeText(this.props.destination.entityCodeClassId, this.props.destination.entityTypeCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses):''} />
                    <this.genInfoRow name={t('parkDistrict')} tooltip={t('tooltip_parkDistrict')} inputValue={typeof this.props.destination.parkDistrictTypeCodeId !== 'undefined' && typeof this.props.destination.parkDistrictTypeCodeClassId != 'undefined' ? this.props.destination.parkDistrictTypeCodeId + ' ' + getCodeText(this.props.destination.parkDistrictTypeCodeClassId, this.props.destination.parkDistrictTypeCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses):''} />
                    <this.genInfoRow name={t('regional_unit')} tooltip={t('tooltip_regional_unit')} inputValue={typeof this.props.destination.regionalUnitTypeCodeId !== 'undefined' && typeof this.props.destination.regionalUnitClassCodeId !== 'undefined' ? this.props.destination.regionalUnitTypeCodeId + ' ' + getCodeText(this.props.destination.regionalUnitClassCodeId, this.props.destination.regionalUnitTypeCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses):''} />
                    <this.genInfoRow name={t('municipality')} tooltip={t('tooltip_municipality')} inputValue={typeof this.props.destination.municipality !== 'undefined' ? this.handleValue(this.props.destination.municipality, 280):''} />
                    <this.genInfoRow name={t('county')} tooltip={t('tooltip_county')} inputValue={typeof this.props.destination.county !== 'undefined' ? this.handleValue(this.props.destination.county, 1005):''} />
                    <this.genInfoRow name={t('coordinate_X')} tooltip={t('tooltip_coordinate_X')} inputValue={this.props.destination.coordinate? parseInt(this.props.destination.coordinate.x_Coordinate):''} />
                    <this.genInfoRow name={t('coordinate_Y')} tooltip={t('tooltip_coordinate_Y')} inputValue={this.props.destination.coordinate? parseInt(this.props.destination.coordinate.y_Coordinate):''} />
                    <this.genInfoRow name={t('coordinate_N')} tooltip={t('tooltip_coordinate_N')} inputValue={this.props.destination.coordinate? parseFloat(this.props.destination.coordinate.n_Coordinate).toFixed(8):''} />
                    <this.genInfoRow name={t('coordinate_E')} tooltip={t('tooltip_coordinate_E')} inputValue={this.props.destination.coordinate? parseFloat(this.props.destination.coordinate.e_Coordinate).toFixed(8):''} />
                    <this.genInfoRow name={t('destination_creator')} tooltip={t('tooltip_destination_creator')} inputValue={typeof this.props.destination.createdBy !== 'undefined' ? this.props.destination.createdBy:''} />
                    <this.genInfoRow name={t('createdTime')} tooltip={t('tooltip_createdTime')} inputValue={typeof this.props.destination.created !== 'undefined' ? new Intl.DateTimeFormat(this.props.t('common:GMLLanguage'), { dateStyle: 'short', timeStyle: 'short'}).format(Date.parse(this.props.destination.created)):''} />
                    <this.genInfoRow name={t('destination_modifier')} tooltip={t('tooltip_destination_modifier')} inputValue={typeof this.props.destination.modifiedBy !== 'undefined'? this.props.destination.modifiedBy:''} />
                    <this.genInfoRow name={t('modifiedTime')} tooltip={t('tooltip_modifiedTime')} inputValue={typeof this.props.destination.modified !== 'undefined' ? new Intl.DateTimeFormat(this.props.t('common:GMLLanguage'), { dateStyle: 'short', timeStyle: 'short'}).format(Date.parse(this.props.destination.modified)):''} />

                </>

                }
                </div>
            </>

        )
    }
}
export default withRouter(ViewPlace)