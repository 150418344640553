import React from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import {GetCounts, GetCountsByFlowId, GetFlows, GetSites} from "../utils/NastaApiClient";
import Count from "../dataClasses/Count";
import moment from "moment-timezone/moment-timezone-utils";
import {Flow} from "../dataClasses/Flow";
import Stack from "@mui/material/Stack";

import {default as MPagination} from "@mui/material/Pagination/Pagination";
import {Paper, TableBody, TableCell, TableHead, TableRow, Table, TableContainer} from "@mui/material";
import UploadingNotification from "../widgets/UploadingNotification/UploadingNotification";


const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const {t} = useTranslation('count', 'common')



    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
            params={params}
            navigate={navigate}

        />
    );
};

class ListCount extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paginationOffset:0,
            allLoaded:false,

            /**
             *
             * @type {Count[]}
             */
            countList:[],

            /**
             *
             * @type {Flow[]}
             */
            flowList: []
        }
        this.loadData= this.loadData.bind(this);
        this.setPagination=this.setPagination.bind(this);

    }

    async componentDidMount() {

        let pageNumber = this.props.params.page;
        if(typeof this.props.params.page!=='undefined'){
            pageNumber=parseInt(pageNumber);
            this.setState({paginationOffset:pageNumber})
        }
        await this.loadData();

    }

    async loadData(){
        await GetCountsByFlowId(this.props.params.id).then(async x=> {

                let tmpCountList = x.map(count => new Count(count));
                let orderedCountList=tmpCountList.sort((a,b)=>{
                        if(a.countId<b.countId){
                            return 1;
                        }
                        if(a.countId>b.countId){
                            return -1;
                        }
                        return 0;
                    }
                )

               await GetFlows().then(flows=>{
                    this.setState({flowList:flows.data.map(flow=>new Flow(flow))}, ()=>{

                        this.setState({allLoaded: true, countList: orderedCountList}, ()=>{

                            if(typeof this.props.params.id !== 'undefined'){
                                this.setState({
                                    countList: this.state.countList.filter(count=>{
                                        return count.flowId===parseInt(this.props.params.id)
                                    })
                                })
                            }

                        })

                    })
                })
            }
        )
    }

    
    setPagination(pageNumber){
        let page = parseInt(pageNumber)
        this.setState({paginationOffset: page}, ()=>{
            this.props.navigate("/count/list/"+this.props.params.id+"/"+page)
        });

    }


    async componentDidUpdate(prevProps, prevState, snapshot) {


        if(typeof this.props.params.page!=='undefined'){
            let page=parseInt(this.props.params.page);
            if(page-1!==this.state.paginationOffset){
                this.setState({paginationOffset:page-1});
            }
        }
        let shouldReloadData=localStorage.getItem("countListShouldReload");
        if(shouldReloadData){
            await this.loadData();
            localStorage.removeItem("countListShouldReload");
        }

        if(typeof this.props.addButtonPressed !== 'undefined'){
            if(this.props.addButtonPressed===true){
                this.props.navigate("/count/add/"+this.props.params.id )
            }
        }
        if(typeof this.props.removeButtonPressed !== 'undefined'){
            if(this.props.removeButtonPressed===true){
                this.props.navigate("/counts/delete/"+this.props.params.id);
            }
        }
    }

    render() {
        let {t} = this.props;

        let allLoaded = this.state.allLoaded;

        let maskedCountList=this.state.countList.slice(this.state.paginationOffset*50, (this.state.paginationOffset*50)+50);
        return (
            <>


                <UploadingNotification showModal={allLoaded===false} />
                {allLoaded === true ? <>



                    <Stack alignItems={"center"} spacing={2} >
                        <p style={{textAlign: "center",marginBottom:"-2vh"}}>
                            {((this.state.paginationOffset) * 50)+1}
                            - {(this.state.paginationOffset) * 50 + 50 < this.state.countList.length ? (this.state.paginationOffset) * 50 + 50 : this.state.countList.length} /
                            {Math.ceil(this.state.countList.length)}
                        </p>
                        <MPagination
                            page={this.state.paginationOffset+1}
                            onChange={(e,val)=>{
                                this.setPagination(val);
                            }}
                            count={Math.ceil(this.state.countList.length/50)}
                            boundaryCount={1}
                            siblingCount={4}
                            color={"primary"} />
                    </Stack>

                    <TableContainer component={Paper}>

                        <Table sx={{}} aria-label={t("flow_list")}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <b>
                                        {t('countId')}
                                        </b>
                                    </TableCell>
                                    <TableCell>
                                        <b>
                                        {t('periodBeginTime')}
                                        </b>
                                    </TableCell>
                                    <TableCell>
                                        <b>
                                        {t('periodEndTime')}
                                        </b>
                                    </TableCell>
                                    <TableCell>
                                        <b>
                                        {t('countsOriginal')}
                                        </b>
                                    </TableCell>
                                    <TableCell>
                                        <b>
                                        {t('flowName')}
                                        </b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    maskedCountList.map(count => {
                                        return <>

                                            <TableRow>
                                                <TableCell><Link to={"/count/"+count.countId}>{count.countId}</Link></TableCell>
                                                <TableCell>{moment(count.periodBeginTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD.MM.YYYY HH:mm')}</TableCell>
                                                <TableCell>{moment(count.periodEndTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD.MM.YYYY HH:mm')}</TableCell>
                                                <TableCell>{count.countsOriginal}</TableCell>
                                                <TableCell>
                                                    {typeof this.state.flowList.find(flow=>flow.flowId===count.flowId)?.flowName!=='undefined' &&
                                                    <Link to={"/flow/"+count.flowId}>{this.state.flowList.find(flow=>flow.flowId===count.flowId)?.flowName}</Link>

                                                    }
                                                </TableCell>
                                            </TableRow>

                                        </>
                                    })
                                }

                            </TableBody>

                        </Table>
                    </TableContainer>

{/*                            <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th>{t('countId')}</th>
                            <th>{t('periodBeginTime')}</th>
                            <th>{t('periodEndTime')}</th>
                            <th>{t('countsOriginal')}</th>
                            <th>{t('flowName')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            maskedCountList.map(count => {
                                return <>

                                    <tr>
                                        <td><Link to={"/count/"+count.countId}>{count.countId}</Link></td>
                                        <td>{moment(count.periodBeginTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD.MM.YYYY HH:mm')}</td>
                                        <td>{moment(count.periodEndTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD.MM.YYYY HH:mm')}</td>
                                        <td>{count.countsOriginal}</td>
                                        <td><Link to={"/flow/"+count.flowId}>{this.state.flowList.find(flow=>flow.flowId===count.flowId).flowName}</Link></td>

                                    </tr>

                                </>
                            })
                        }
                        </tbody>

                    </table>*/}

                    <Stack alignItems={"center"} spacing={2} >
                        <MPagination
                            page={this.state.paginationOffset+1}
                            onChange={(e,val)=>{
                               this.setPagination(val);
                            }}
                            count={Math.ceil(this.state.countList.length/50)}
                            boundaryCount={1}
                            siblingCount={4}
                            color={"primary"} />
                    </Stack>

                    </>
                :<></>}
            </>
        )
    }
}

export default withRouter(ListCount)