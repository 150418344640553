import React, {createRef} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import FormController from "../widgets/FormController/FormController";
import {AxiosError} from "axios";
import {
    GetCodeClasses,
    GetCounts,
    GetCountsByFlowId,
    GetFlow,
    GetFlows,
    GetSites,
    InsertCount
} from "../utils/NastaApiClient";
import {Flow} from "../dataClasses/Flow";
import MultiChoiceSearchDropdown from "../widgets/MultiChoiceSearchDropdown/MultiChoiceSearchDropdown";
import {CodeClass} from "../dataClasses/CodeClass/CodeClass";
import ValidatedInputField from "../widgets/ValidatedInputField/ValidatedInputField";

import { useSelector, useDispatch, useStore} from 'react-redux';
import * as codesSelector from '../mapViewOl/codesSelector';


import "flatpickr/dist/themes/light.css";

import moment from "moment-timezone";
import 'moment/locale/fi'
import Count from "../dataClasses/Count";
import {Site} from "../dataClasses/Site/Site";

import Button from "@mui/material/Button";

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {IconButton, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import UploadingNotification from "../widgets/UploadingNotification/UploadingNotification";
import ModalComponent from "../widgets/ModalComponent/ModalComponent";

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const {t} = useTranslation('count', 'common');
    const codes = useSelector((state) => state.codes);
    const dispatch = useDispatch();
    const storeHelper = useStore();

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
            params={params}
            navigate={navigate}
            dispatch={dispatch}
            storeHelper={storeHelper}
            codes={codes}
        />
    );
};

class AddCount extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            codeClassesLoaded: false,
            countsLoaded: false,
            sitesLoaded: false,
            flowsLoaded: false,
            codeClasses: undefined,
            /**
             *
             * @type {Site[]}
             */
            allSites: [],
            /**
             *
             * @type {Flow[]}
             */
            allFlows: [],
            validFlows: [],
            /**
             *
             * @type {Count[]}
             */
            counts: [],

            /**
             *
             * @type {Count}
             */
            latestCount: null,
            submitAttempted: false,
            dateErrorMessage: "",
            nonce: crypto.randomUUID(),
            count: {
                flowId: null,
                periodBeginTime: null,
                periodEndTime: null,
                countsOriginal: null,
                description: null,
                astaCounterId: 0,
                createdBy: typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '',
                modifiedBy: typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : ''
            },

            dateValid: false,
            allValid: false

        }
        this.setTargetFlow = this.setTargetFlow.bind(this);
        this.genInfoRow = this.genInfoRow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkDateValidity = this.checkDateValidity.bind(this);
        this.resetData = this.resetData.bind(this);
    }

    /**
     *
     * @returns {boolean}
     */
    allLoaded() {
        let {
            countsLoaded,
            sitesLoaded,
            flowsLoaded,
        } = this.state;

        return (countsLoaded && sitesLoaded && flowsLoaded)
    }

    async componentDidMount() {
        let id = this.props.params.id;

        GetSites().then(sites => {

            this.setState({allSites: sites.data.map(x=>new Site(x)), sitesLoaded: true}, () => {

                GetFlows().then(flowList => {
                    let tmpFlowList = flowList.data.map(flow => new Flow(flow));
                    this.setState({allFlows: tmpFlowList}, () => {
                        if (typeof this.props.params.id !== 'undefined') {
                            this.setTargetFlow(id);
                        }
                        this.setState({flowsLoaded: true});

                    })

                });
            });
        });
        GetCountsByFlowId(this.props.params.id).then(counts => {

            /**
             *
             * @type {Count[]}
             */
            let tmpCounts = counts.length > 0 ? counts.map(count =>{

                let tmpCount=new Count(count);
                tmpCount.periodBeginTime=moment(tmpCount.periodBeginTime);
                tmpCount.periodEndTime=moment(tmpCount.periodEndTime);

                return  tmpCount;
            }) : [];

            if (typeof this.props.params.id !== 'undefined') {


                let latestCount = tmpCounts.length > 0 ? tmpCounts : [];
                if(latestCount.length>0){
                    latestCount = latestCount.reduce((acc, count)=>{
                        return acc.periodEndTime.isAfter(count.periodEndTime) && acc.flowId===this.props.params.id?acc:count;
                    });
                }



                if (latestCount.flowId === parseInt(this.props.params.id)) {

                    this.setState({
                        counts: tmpCounts,
                        latestCount: latestCount,
                        countsLoaded: true,
                        count: {
                            ...this.state.count,
                            periodBeginTime: latestCount.periodEndTime,
                            periodEndTime: null
                        }
                    });
                } else {
                    this.setState({
                        counts: tmpCounts,
                        countsLoaded: true,
                        count: {
                            ...this.state.count,
                            periodEndTime: null
                        }
                    });
                }

            } else {

                this.setState({
                    counts: tmpCounts,
                    countsLoaded: true,
                    count: {
                        ...this.state.count,
                        periodEndTime: null
                    }
                });
            }


        })

        try{
            if(!this.props.storeHelper.getState().codesSelector.codes.codeClassesLoaded){
                let response = await GetCodeClasses();

                if (response instanceof AxiosError) {
                    this.setState({error: response});
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(false));
                } else {
                    this.props.dispatch(codesSelector.fillCodeClasses(response));
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(true));
                }
            }
        }
        catch(error){
            console.log(error);
        }


    }


    getNameFromCodeClass(name, id) {
        let lang = this.props.storeHelper.getState().codesSelector.codes.codeClasses?.find(cc => cc.name === name)?.codes.find(cc => cc.codeId === id);
        let text = lang?.languages.find(lang => lang["languageName"] === this.props.t('common:GMLLanguage')).text;
        return text;


    }

    setTargetFlow(id) {
        let tmpValidFLow = this.getValidFlows().find(flow => {
            return flow.flowId === parseInt(id);
        })
        this.setState({
            count: {...this.state.count, flowId: (id !== null ? tmpValidFLow : null)}
        }, () => {

            let flowId = this.state.count.flowId;

            if (flowId !== null && typeof flowId !== 'undefined') {
                let counts = this.state.counts;
                let tmpCounts = counts.length > 0 ? counts.map(count => new Count(count)) : [];


                let tmpLatestCount = tmpCounts.length > 0 ? tmpCounts : [];

                if (tmpLatestCount.length > 0) {
                    tmpLatestCount = tmpLatestCount.filter(count => count.flowId === this.state.count.flowId.flowId);
                    if (tmpLatestCount.length > 0) {
                        tmpLatestCount = tmpLatestCount.reduce((prev, current) => {
                            return (prev.periodEndTime.isAfter(current.periodEndTime) && prev.flowId === parseInt(flowId.flowId)) ? prev : current
                        });
                    }
                }

                if (tmpLatestCount.flowId === parseInt(this.state.count.flowId.flowId)) {
                    this.setState({
                        counts: tmpCounts,
                        latestCount: tmpLatestCount,
                        countsLoaded: true,
                        count: {
                            ...this.state.count,
                            periodBeginTime: tmpLatestCount.periodEndTime,
                            periodEndTime: null
                        }
                    });
                } else {
                    this.setState({
                        latestCount: null,
                        count: {
                            ...this.state.count,
                            periodBeginTime: null,
                            periodEndTime: null
                        }
                    });
                }
            } else {
                this.setState({
                    latestCount: null,
                    count: {
                        ...this.state.count,
                        periodBeginTime: null,
                        periodEndTime: null
                    }
                });
            }

        })


    }

    mapFlowToMCSD(data) {
        if (typeof data !== 'undefined') {

            let d = data;
            if (typeof data.length === 'undefined') {
                d = [data];
            }
            return d.map(flow => {
                    let f = new Flow(flow)
                    return {
                        id: f.flowId,
                        name: f.flowName,
                        title: f.flowId.toString()
                    }
                }
            )
        }
    }

    async handleSubmit() {
        if (this.state.allValid && this.state.dateValid) {


            // {...obj} to prevent manipulations from propagating back to state
            let tmpCount = {...this.state.count}

            tmpCount.nonce = this.state.nonce;
            tmpCount.periodBeginTime = this.formatTime(tmpCount.periodBeginTime);
            tmpCount.periodEndTime = this.formatTime(tmpCount.periodEndTime);
            tmpCount.flowId = tmpCount.flowId.flowId

            await InsertCount(tmpCount).then(x => {

                this.setState({submitAttempted: true});

                localStorage.setItem("countListShouldReload", true);
                this.props.onSubmit("Success", this.props.t("Success"));
                this.props.navigate("/count/list/"+tmpCount.flowId);
            });


        } else {
            this.setState({submitAttempted: true}, () => {
                this.props.onSubmit("Warning", this.props.t("common:one_or_more_required_field_is_invalid"));
            });
        }

    }

    /**
     *
     * @returns {Flow[]}
     */
    getValidFlows() {

        let tmpSites = [];



        for (let site of this.state.allSites) {
            if (site.status!==null && site.status.codeId === 3) {
                tmpSites.push(site.siteId);
            }
        }

        let tmpFlows = [];
        for (let flow of this.state.allFlows) {
            if (tmpSites.includes(flow.siteId)) {
                tmpFlows.push(flow);
            }
        }

        return tmpFlows;
    }

    genInfoRow(props) {
        let {t} = this.props;
        let {name, tooltip, inputValue, valueIfPresent} = props

        return (
            <div className={"column col-12"}>
                <div className={"columns"} style={{paddingTop: "5px", borderBottom: "2px solid #D8D8D8"}}>
                    <b className={"column col-6"}>
                        {t(name)}
                        {(typeof tooltip!=='undefined' && t(tooltip)!==null && t(tooltip)!=='') &&
                        <Tooltip title={t(tooltip)}>
                            <IconButton>
                                <InfoIcon/>

                            </IconButton>
                        </Tooltip>
                        }
                        :
                    </b>

                    <p className={"column col-6"}>
                        {typeof props.linkTo !== 'undefined' ? <Link to={props.linkTo}>
                                {typeof inputValue === 'undefined' ? '' : (typeof valueIfPresent !== 'undefined' ? valueIfPresent : inputValue)}
                            </Link> :
                            typeof inputValue === 'undefined' ? '' : (typeof valueIfPresent !== 'undefined' ? valueIfPresent : inputValue)
                        }

                    </p>

                </div>
            </div>)
    }

    formatTime(dateString) {
        return moment.tz(dateString, Intl.DateTimeFormat().resolvedOptions().timeZone).tz("UTC").format('YYYY-MM-DDTHH:mm:ss[Z]')
    }

    checkDateValidity() {
        if (typeof this.state.count.flowId !== 'undefined' && this.state.count.flowId !== null) {


            let valid = false;
            let end = moment(this.state.count.periodEndTime);
            let begin = moment(this.state.count.periodBeginTime);


            let msg = "";



            for (const count of this.state.counts) {
                if (count.flowId === this.state.count.flowId.flowId) {

                    if (begin.isBetween(count.periodBeginTime, count.periodEndTime)
                        || end.isBetween(count.periodBeginTime, count.periodEndTime)
                        || count.periodBeginTime.isBetween(begin,end)
                        || count.periodEndTime.isBetween(begin,end)
                        || begin.isSame(count.periodBeginTime)

                        || end.isSame(count.periodEndTime)
                    ) {
                        msg = this.props.t('date_already_reserved_in') + ' ' + count.countId + '  '
                            + count.periodBeginTime.tz("Europe/Helsinki").format("DD.MM.YYYY HH:mm") + ' - ' + count.periodEndTime.tz("Europe/Helsinki").format("DD.MM.YYYY HH:mm") + ' ';

                        break;
                    }
                }
            }
            if (msg.length === 0) {
                valid = true
            }

            this.setState({
                dateErrorMessage: msg,
                dateValid: (valid === true ? end.isAfter(begin) : false)
            })
        }
    }

    resetData() {

        this.setState({
            submitAttempted: false,
            nonce: crypto.randomUUID(),
            flowMCSD: [],
            count: {
                flowId: null,
                periodBeginTime: null,
                periodEndTime: null,
                countsOriginal: 0,
                description: "",
                astaCounterId: 0,
                createdBy: typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '',
                modifiedBy: typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : ''
            }
        })
    }

    render() {
        let {t} = this.props;


        let flowId = this.state.count.flowId;
        let allFlows = this.state.allFlows;

        let flowDirectionName = "";
        let userTypeName = "";
        if (typeof flowId !== 'undefined' && flowId !== null) {
            flowDirectionName = this.getNameFromCodeClass('flowDirection', flowId?.flowDirectionType?.codeId);
            userTypeName = this.getNameFromCodeClass('userType', flowId?.userType?.codeId);
        }


        let isEnabled=true;
        for (const site of this.state.allSites) {
            if(site.siteId===allFlows.find(f=>f.flowId===parseInt(this.props.params.id))?.siteId){
                if(site.status.codeId===4){
                    isEnabled=false;
                    break;
                }
            }
        }



        return (
            <>

                <ModalComponent
                    showModal={!isEnabled}
                    title={t("site_status_disabled_title")}
                    content={t("site_status_disabled_content")}
                    modalType={"errorModal"}
                    onModalContinue={()=>this.props.navigate(-2)}
                    onModalCancel={()=>{}}
                />

                <br/><br/>

                <UploadingNotification showModal={this.allLoaded()===false} />
                {this.allLoaded() === true && <>
                    <form>

                        {(typeof flowId !== 'undefined' && flowId !== null) && <>

                            <this.genInfoRow name={'flow_id'} tooltip={'flow_id'} inputValue={flowId.flowId}/>
                            <this.genInfoRow name={'flow_name'} tooltip={'flow_name'} inputValue={flowId.flowName}/>
                            <this.genInfoRow name={'flowDirection'} tooltip={'flowDirection'}
                                             inputValue={flowDirectionName}/>
                            <this.genInfoRow name={'visitor_type'} tooltip={'tooltip_visitor_type'}
                                             inputValue={userTypeName}/>
                            <this.genInfoRow name={'calibrationCoefficient_name'}
                                             tooltip={'calibrationCoefficient_name'}
                                             inputValue={flowId?.calibrationCoefficient?.toString().replace(".",",")}/>
                        </>
                        }
                        <FormController onValidityChanged={(validity) => this.setState({allValid: validity})}>



                            <p  className={"" + (this.state.dateErrorMessage.length > 0 && ' label label-rounded label-error')}>{this.state.dateErrorMessage}</p>

                            <div>
                            <label htmlFor={"startTime"}>{t('startTime')}</label><br/>
                            <LocalizationProvider locale={"fi-FI"} dateAdapter={AdapterMoment}>
                                <DateTimePicker ampm={false}


                                id={"startTime"} format={"DD.MM.YYYY HH:mm"}
                                                onChange={(date) => {
                                    this.setState({
                                        count: {
                                            ...this.state.count,
                                            periodBeginTime: date
                                        }
                                    }, () => {
                                        this.checkDateValidity();
                                    });
                                }}
                                                slotProps={{
                                                    textField:{
                                                        inputProps:{
                                                            "id":"startTime"
                                                        }
                                                    }
                                                }}
                                value={this.state.count.periodBeginTime !== null
                                    ? this.state.count.periodBeginTime
                                    : this.state.count?.periodEndTime}
                                />


                            </LocalizationProvider>
                            </div>

                            <div>
                            <label htmlFor={"endTime"}>{t('endTime')}</label><br/>

                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker ampm={false} id={"endTime"}
                                                format={"DD.MM.YYYY HH:mm"} onChange={(date) => {


                                    this.setState({
                                        count: {
                                            ...this.state.count,
                                            periodEndTime: date
                                        }
                                    }, () => {
                                        this.checkDateValidity();
                                    });
                                }}

                                slotProps={{
                                    textField:{
                                        inputProps:{
                                            "id":"endTime"
                                        }
                                    }
                                }}
                                value={moment(this.state.count?.periodEndTime)}
                                PopperProps={{
                                    placement: "bottom-end"
                                }}

                                />
                            </LocalizationProvider>
                            </div>

                            <ValidatedInputField
                                name={"countsOriginal"}
                                fieldName={this.props.t('countsOriginal')}
                                tooltip={this.props.t('tooltip_countsOriginal')}
                                placeholder={this.props.t('placeholder_countsOriginal')}
                                required={true}
                                minLength={1}
                                maxLength={9}
                                type={"text"}
                                fieldId={'countsOriginal'}
                                validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                value={this.state.count.countsOriginal !== null ? this.state.count.countsOriginal : ''}
                                validatorFunction={(x) => {
                                    return true
                                }}
                                onFieldValueChanged={(newValue) => {
                                    this.setState({
                                        count: {
                                            ...this.state.count,
                                            countsOriginal: (isNaN(parseInt(newValue)) ? 0 : parseInt(newValue).toString())
                                        }
                                    })
                                }}

                            />

                            <ValidatedInputField
                                name={"description"}
                                fieldName={this.props.t('description')}
                                tooltip={this.props.t('tooltip_description')}
                                placeholder={this.props.t('placeholder_description')}
                                required={false}
                                minLength={0}
                                maxLength={100}
                                type={"text"}
                                fieldId={'description'}
                                validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                value={this.state.count.description}
                                validatorFunction={() => {
                                    return true
                                }}
                                onFieldValueChanged={(newValue) => {
                                    this.setState({count: {...this.state.count, description: newValue}})
                                }}

                            />
                        </FormController>
                        <Button size={"small"} variant={"contained"} type="button" onClick={() => this.handleSubmit()}
                                className="float-right" disabled={localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" || localStorage.getItem("user-role") === "Nasta.Recorder" ? false : true}><img style={{filter:"invert(100%)", height:"1.5vh"}} src="/img/icon/JHT design system/check_fill.svg" alt={""}/>{t("common:button_save")}
                        </Button>
                    </form>
                </>
                }
            </>
        )
    }
}

export default withRouter(AddCount)