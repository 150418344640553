import axios from "axios";


function createAxios(){
    return axios.create({
        baseURL: process.env['REACT_APP_NASTA_API_BASE_URL'],
        timeout: 0,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("api-token")}`
        }
      });
}

export async function InsertCountsFromCsvFile(file){
    let response;
    let axiosRequest = axios.create({
        baseURL: process.env['REACT_APP_NASTA_API_BASE_URL'],
        timeout: 0,
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("api-token")}`,
            "Content-Type":"multipart/form-data"
        }
    });
    let tmpFormData = new FormData();
    tmpFormData.append("file", file)
    await axiosRequest.post('/counts/ImportCsvToDatabase', tmpFormData)
        .then(res => {
            response = res.data;
        })
        .catch((error) => {
            console.error(error);
            response = error;
        });

    return response;
}

export async function GetCodeClasses() {    
  let response;
  await createAxios().get('/CodesClass/GetCodeClasses')    
        .then(res => {
            response = res.data;
        })
        .catch((error) => {
          console.error(error);
          response = error;
      });

   return response;
}

export async function GetTotalCountOfCounts(flowId) {
    let response;
    await createAxios().get('/Counts/GetTotalCountOfCounts?id='+flowId)
        .then(res => {
            response = res.data;
        })
        .catch((error) => {
            console.error(error);
            response = error;
        });

    return response;
}


export async function GetCountsByFlowId(flowId) {
    let response;
    await createAxios().get('/Counts/GetCountByFlowId?id='+flowId)
        .then(res => {
            response = res.data;
        })
        .catch((error) => {
            console.error(error);
            response = error;
        });

    return response;
}

export async function GetCounts() {
    let response;
    await createAxios().get('/Counts')
        .then(res => {
            response = res.data;
        })
        .catch((error) => {
            console.error(error);
            response = error;
        });

    return response;
}

export async function InsertCount(countData){
    let response;
    await createAxios().post('/Counts', countData)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);
            response = error;
        });
    return response;
}


export async function GetCount(countId){
    let response;
    await createAxios().get("/Counts/" + countId)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);

        });
    return response;
}

export async function DeleteCount(countId){
    let response;
    await createAxios().delete('/Counts/'+ countId)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);
            response = error;
        });
    return response;
}

export async function DeleteCounts(countsList) {
    let response;
    await createAxios().post('/Counts/DeleteCountsList', countsList)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);
            response = error;
        });
    return response;
}


export async function GetDestination(destinationId){
    let response;
    await createAxios().get("/Destinations/" + destinationId)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);

        });
    return response;
}
export async function GetDestinations() {
  let response;
  await createAxios().get('/Destinations')    
        .then(res => {
            response = res.data;
        })
        .catch((error) => {
          console.error(error);
          response = error;
      });

      return response;
}

export async function UpdateDestination(destinationId, updateModel){
  let response;
  await createAxios().put('/Destinations/'+ destinationId, updateModel)
      .then((res) => {
          response = res;
      })
      .catch((error) => {
        console.error(error);
        response = error; 
      });

  return response;
}

export async function DeleteDestination(destinationId){
  let response;
  await createAxios().delete('/Destinations/'+ destinationId)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
          console.error(error);
          response = error;
        });
  return response;
}

export async function InsertDestination(destinationData){
  let response;
  await createAxios().post('/Destinations', destinationData)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);
            response = error;
        });
  return response;
}


export async function DestinationDeleteAllowed(destinationId){
    let response;
    await createAxios().get('/Destinations/DeleteAllowed/'+ destinationId)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);
            response = error;
        });
    return response;
}

export async function InserSite(siteData) {
    let response;
    await createAxios().post('/Sites', siteData)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);
            response = error;
        });
    return response
}

export async function UpdateSite(siteId, updateModel){
  let response;
  await createAxios().put('/Sites/'+ siteId, updateModel)
      .then((res) => {
          response = res;
      })
      .catch((error) => {
          console.error(error);
          response = error;
      });
  return response;
}


export async function SiteDeleteAllowed(siteId){
    let response;
    await createAxios().get("/Sites/DeleteAllowed/"+siteId)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);

        });
    return response;
}


export async function SiteDestinationsDeleteAllowed(destinationIds, siteId){
    let requestObject = {
        "destinationIds":destinationIds,
        "siteId":parseInt(siteId)
    }
    let response;
    await createAxios().post('/Sites/SiteDestinations/DeleteAllowed', requestObject)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);
            response = error;
        });
    return response;
}

export async function DeleteSite(siteId){
  let response;
  await createAxios().delete('/Sites/'+ siteId)
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        console.error(error);
        response = error;
      });
  return response;
}


export async function GetUserCreatedSites(){
    let response;
    await createAxios().get("/Sites/GetUserCreatedSites")
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);

        });
    return response;
}

export async function GetUserCreatedDestinations(){
    let response;
    await createAxios().get("/Destinations/GetUserCreatedDestinations")
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);

        });
    return response;
}

export async function GetUserCreatedSite(siteId){
    let response;
    await createAxios().get("/Sites/GetUserCreatedSite/" + siteId)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);

        });
    return response;
}

export async function GetUserCreatedDestination(destinationId){
    let response;
    await createAxios().get("/Destinations/GetUserCreatedDestination/" + destinationId)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);

        });
    return response;
}


export async function GetCounties(){
    let response;
    await createAxios().get("/Map/GetCounties")
    .then((res) => {
        response = res;
    })
    .catch((error) => {
        console.error(error);

    });
    return response;
}

export async function GetMunicipalities(){
    let response;
    await createAxios().get("/Map/GetMunicipalities")
    .then((res) => {
        response = res;
    })
    .catch((error) => {
        console.error(error);

    });
    return response;
}

export async function GetSites(){
  let response;
  await createAxios().get("/Sites")
  .then((res) => {
      response = res;
  })
  .catch((error) => {
      console.error(error);
      
  });
  return response;
}

export async function GetSitesMinimumData(){
    let response;
    await createAxios().get("/MinData")
    .then((res) => {
        response = res;
    })
    .catch((error) => {
        console.error(error);
        
    });
    return response;
  }

export async function GetSite(siteId){
  let response;
  await createAxios().get("/Sites/" + siteId)
  .then((res) => {
      response = res;
  })
  .catch((error) => {
      console.error(error);
      
  });
  return response;
}

export async function GetSitesByDestinationId(destinationId){
    let response;
    await createAxios().get("/Sites/sitesByDestinationId/" + destinationId)
    .then((res) => {
        response = res;
    })
    .catch((error) => {
        console.error(error);
        
    });
    return response;
  }

export async function GetFlows(){
  let response;
  await createAxios().get("/Flow/")
      .then((res) => {
          response = res;
          })
      .catch((error) => {
        console.error(error);
        response = error;
      });
  return response;
}

export async function GetFlowsBySiteCount(){
    let response;
    await createAxios().get("/FlowsBySiteCount")
        .then((res) => {
            response = res;
            })
        .catch((error) => {
          console.error(error);
          response = error;
        });
    return response;
  }

export async function GetFlow(flowId){
  let response;
  await createAxios().get("/Flow/" + flowId)
      .then((res) => {
          response = res;
      })
      .catch((error) => {
          console.error(error);
          response = error;
      });
  return response;
}

export async function GetFlowsBySiteId(siteId){ 
    let response;
  await createAxios().get("/Flow/BySiteId/" + siteId)
      .then((res) => {
          response = res;
      })
      .catch((error) => {
          console.error(error);
          response = error;
      });
  return response;
}

export async function DeleteFlow(flowId){
  let response;
  await createAxios().delete('/Flow/' + flowId)
        .then((res)=>{
            response = res;
        }).catch((error)=>{
        console.error(error);
        response = error;
    });
  return response;
}

export async function UpdateFlow(flowId, flow){
  let response;
  await createAxios().put('/Flow/' + flowId, flow)
  .then((res)=>{
      response = res;
  })
  .catch((error)=>{
      console.error(error);
      response = error;
  });
  return response;
}

export async function InsertFlow(flow){
  let response;
  await createAxios().post('/Flow', flow)
    .then((res) => {
      response = res;
    })
    .catch((error) => {
        console.error(error);
        response = error;
    });
  return response;
}

export async function GetRegionalUnits(){
    let response;
    await createAxios().get("/Map/GetRegionalUnits")
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);

        });
    return response;
}

export async function GetParkDistricts(){
    let response;
    await createAxios().get("/Map/GetParkDistricts")
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);

        });
    return response;
}

export async function GetEntities(){
    let response;
    await createAxios().get("/Map/GetEntities")
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);

        });
    return response;
}

let landUseUnitCache;
export async function GetLandUseUnits(){


    if(typeof landUseUnitCache !== 'undefined'){
        return await landUseUnitCache
    }
   landUseUnitCache = createAxios().get("/Map/GetLandUseUnits")
        .catch((error) => {
            console.error(error);
        });

    landUseUnitCache = await landUseUnitCache;
    return landUseUnitCache;
}

export async function GetLandUseUnitsData(){
    let response;
    await createAxios().get("/Map/GetLandUseUnitIdAndNameList")
        .then((res) => {
            response = res;
            })
        .catch((error) => {
          console.error(error);
          response = error;
        });
    return response;
  }

export async function InsertCountingRule(countingRule){
    let response;
    await createAxios().post('/CountingRules', countingRule)
      .then((res) => {
        response = res;
      })
      .catch((error) => {
          console.error(error);
          response = error;
      });
    return response;
  }

  export async function GetCountingRules(){
    let response;
    await createAxios().get("/CountingRules/")
        .then((res) => {
            response = res;
            })
        .catch((error) => {
          console.error(error);
          response = error;
        });
    return response;
  }

  
  export async function GetCountingsCountByDestination(){
    let response;
    await createAxios().get("/CountingCountByDestination")
        .then((res) => {
            response = res;
            })
        .catch((error) => {
          console.error(error);
          response = error;
        });
    return response;
  }

  export async function GetCountingRulesForList(){
    let response;
    await createAxios().get("/CountingRulesForList")
        .then((res) => {
            response = res;
            })
        .catch((error) => {
          console.error(error);
          response = error;
        });
    return response;
  }

  export async function GetCountingRuleCountByDestination(){
    let response;
    await createAxios().get("/CountingRuleCountByDestination")
        .then((res) => {
            response = res;
            })
        .catch((error) => {
          console.error(error);
          response = error;
        });
    return response;
  }

  export async function UpdateCountingRule(countingRuleId, updateModel){
    let response;
    await createAxios().put('/CountingRules/'+ countingRuleId, updateModel)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
          console.error(error);
          response = error; 
        });
  
    return response;
  }

  
  export async function GetCountingRule(countingRuleId){
    let response;
    await createAxios().get("/CountingRules/" + countingRuleId)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);
            response = error;
        });
    return response;
  }

  export async function IsCountingRuleInUse(countingRuleId){
    let response;
    await createAxios().get("/CountingRules/CheckIsRuleInUse/" + countingRuleId)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);
            response = error;
        });
    return response;
  }

  export async function GetActiveCountingRulesByDestinationId(destinationId){
    let response;
    await createAxios().get("/CountingRules/AllActiveByDestinationId/" + destinationId)
        .then((res) => {
            response = res;
            })
        .catch((error) => {
          console.error(error);
          response = error;
        });
    return response;
  }

  export async function DeleteCountingRule(countingRuleId){
    let response;
    await createAxios().delete('/CountingRules/'+ countingRuleId)
        .then((res) => {
            response = res;
        })
        .catch((error) => {
            console.error(error);
            response = error;
        });
    return response;
}

  export async function GetCountingRulesByDestinationId(destinationId){
    let response;
    await createAxios().get("/CountingRules/AllByDestinationId/" + destinationId)
        .then((res) => {
            response = res;
            })
        .catch((error) => {
          console.error(error);
          response = error;
        });
    return response;
  }

  export async function GetCountings(){
    let response;
    await createAxios().get("/Counting/")
        .then((res) => {
            response = res;
            })
        .catch((error) => {
          console.error(error);
          response = error;
        });
    return response;
  }

  export async function GetCountingsForList(){
    let response;
    await createAxios().get("/CountingsForList")
        .then((res) => {
            response = res;
            })
        .catch((error) => {
          console.error(error);
          response = error;
        });
    return response;
  }

  export async function ExecuteCounting(countingData){
    let response;
    await createAxios().post('/Counting', countingData)
      .then((res) => {
        response = res;
      })
      .catch((error) => {
          console.error(error);
          response = error;
      });
    return response;
  }

  export async function GetCountingById(countingId){
    let response;
    await createAxios().get("/Counting/" + countingId)
        .then((res) => {
            response = res;
            })
        .catch((error) => {
          console.error(error);
          response = error;
        });
    return response;
  }

  export async function GetCountingsByDestinationId(destinationId){
    let response;
    await createAxios().get("/Counting/ByDestinationId/" + destinationId)
        .then((res) => {
            response = res;
            })
        .catch((error) => {
          console.error(error);
          response = error;
        });
    return response;
  }

  export async function GetNextCountingStartDate(countingRuleId){
    let response;
    await createAxios().get("/Counting/NextCountingStartingDateByCountingRuleId/" + countingRuleId)
        .then((res) => {
            response = res;
            })
        .catch((error) => {
          console.error(error);
          response = error;
        });
    return response;
  }

  export async function DeleteCounting(deleteData){
    let response;
    await createAxios().post('/Counting/HandleCountingsRemoval', deleteData)
          .then((res)=>{
              response = res;
          }).catch((error)=>{
          console.error(error);
          response = error;
      });
    return response;
  }

  export async function PublishCountings(publishData){
    let response;
    await createAxios().post('/Counting/PublishCountings', publishData)
          .then((res)=>{
              response = res;
          }).catch((error)=>{
          console.error(error);
          response = error;
      });
    return response;
  }

  export async function UnpublishCountings(unpublishData){
    let response;
    await createAxios().post('/Counting/UnpublishCountings', unpublishData)
          .then((res)=>{
              response = res;
          }).catch((error)=>{
          console.error(error);
          response = error;
      });
    return response;
  }

 
