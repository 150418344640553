import React from "react";
import {useTranslation} from "react-i18next";
import {
    Box,
    Modal,
    Button,
    Typography,
    useTheme,
   Divider,
} from "@mui/material";
import {useNavigate} from "react-router-dom";

const withRouter = WrappedComponent => props => {

    const {t} = useTranslation('errorDialog', 'common');
    const navigate = useNavigate();

    const theme = useTheme();
    return (
        <WrappedComponent
            t={t}
            {...props}
            theme={theme}
            navigate={navigate}
        />
    );
};


class CountingDialog extends React.Component {
    selectedModalType = undefined;

    constructor(props) {
        super(props);
        this.state = {
            message: props.message,
            title: props.title,
            showSecondButton: props.showSecondButton
        }
    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.message !== this.props.message){
            this.setState({message: this.props.message});
        }
        if(prevProps.title !== this.props.title){
            this.setState({title: this.props.title});
        }
        if(prevProps.showSecondButton !== this.props.showSecondButton){
            this.setState({showSecondButton: this.props.showSecondButton});
        }
    }

    render() {
        const {t} = this.props
        return (
            <>

                <Modal
                    open={this.props.showModal}
                    onClose={() => this.props.onModalCancel()}
                    aria-labelledby={'title'}
                    aria-describedby={this.props.content}>
                    <Box className={"modal-box-container"} style={{width:'1000px'}}>

                        <Typography variant={"h6"}>{this.state.title}</Typography>

                        <Divider/>

                        <Typography variant="h5" gutterBottom>
                            {this.state.message} 
                        </Typography>
                        <br/>

                        {this.state.showSecondButton && <Button size={"small"} variant={"contained"} className="float-right"
                                onClick={() => this.props.onExecute()}>{t('errorDialog:second_button_text')}
                        </Button>}
                            
                        <Button size={"small"} variant={"contained"} className="float-right"
                                onClick={() => this.props.onModalCancel()}>{t('errorDialog:close_button_text')}
                        </Button>
                    </Box>
                </Modal>


            </>
        )
    }
}

export default withRouter(CountingDialog)