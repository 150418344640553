import React from "react";
import MainMenu from "./MainMenu";
import UserMenu from "./UserMenu";
import {Link, useLocation, useNavigate} from "react-router-dom";
import LanguageSelector from "../LanguageSelector";
import {useTranslation} from "react-i18next";
import {useMsal} from "@azure/msal-react";
import "./NavBar.css";
import Button from "@mui/material/Button";
import siteIcon from '../icons/style-two-pin-marker_fill.svg';
import destinationIcon from '../icons/Property 1=Filled-4.svg';
import waveIcon from '../icons/Property 1=Filled-5.svg';
import countingRuleIcon from '../icons/Property 1=Filled-3.svg';
import countingIcon from '../icons/Property 1=Filled-2.svg';

const withProps = WrappedComponent => props => {
    const {instance} = useMsal();
    const {t} = useTranslation('navbar', 'common');
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, location/* other injected props */}}
            navigate={navigate}
        />
    );
};

class NavBar extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            mainMenuVisible:false,
        userMenuVisible:false,
        updatedLanguage: false}
    }

    getLocalizedUserRightsText (){
        
        let role = localStorage.getItem("user-role")
        if(typeof role !== 'undefined' && role !== null && role.length > 0){
                return this.props.t('common:' + role);
        }
        return '';
    }

    componentDidMount() {
        this.setState({updatedLanguage: true});
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.t !== prevProps.t) {
            this.setState({updatedLanguage: true});
          }
    }



    render(){

        return (
            <>
            <header>
                <div className="main-header main-header-placement">
                    <i onClick={()=>{this.setState({mainMenuVisible:!this.state.mainMenuVisible});}} className="icon icon-menu c-hand hide-menu mobile-only"></i>
                    {this.state.mainMenuVisible?<MainMenu onSelectLink={()=>this.setState({mainMenuVisible:false})} />:""}
                    <Link to="/" className="btn btn-link"><img className={"mh-logo"} alt={this.props.t("common:alt_mh_logo")} src={"/img/metsa-logo.png"}/></Link>

                    <LanguageSelector/>
                </div>

                <a className={"hidden-skip-link"} href="#mainContent" aria-label={this.props.t("skip_to_main_content")}>{this.props.t("skip_to_main_content")}</a>
            <div className="navbar">
                <section className="navbar-section">
                </section>
                <section className="navbar-section">

                </section>
                <section>
                    <div className="user-viewer">
                        <span className="user-viewer-text" >{typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : ''} | {this.getLocalizedUserRightsText()}</span>
                        <Button aria-label={this.props.t("common:user_menu")} size={"small"}  variant={"contained"} onClick={()=>{this.setState({userMenuVisible:!this.state.userMenuVisible});}} >
                            <i alt={""} className=" icon icon-people c-hand"></i>
                        </Button>
                    </div>

                    {this.state.userMenuVisible?<UserMenu onSelectLink={()=>this.setState({userMenuVisible:false})}  />:""}

                </section>
        </div>

            <nav className="navbar-link-buttons desktop-only">



                <Button
                    size={"small"}
                    variant={"contained"}
                    startIcon={<img style={{filter:this.props.location.pathname.startsWith('/destination')?"":"invert(100%)"}} src={destinationIcon} alt={""}/>}
                        href={"/destinations/add"}
                        type={"button"}
                    sx={{color:this.props.location.pathname.startsWith('/destination')?"black !important":"white !important"}}
                        color={this.props.location.pathname.startsWith('/destination')?"secondary":"primary"}
                >{this.props.t("destinations_button")}
                </Button>


                <Button

                    startIcon={<img style={{filter:this.props.location.pathname.startsWith('/site')?"":"invert(100%)"}} src={siteIcon} alt={""}/>}
                    size={"small"}
                        variant={"contained"}
                        href={"/sites/add"}
                        type={"button"}
                    sx={{color:this.props.location.pathname.startsWith('/site')?"black !important":"white !important"}}
                        color={this.props.location.pathname.startsWith('/site')?"secondary":"primary"}
                >
                    {this.props.t("sites_button")}
                </Button>



                <Button size={"small"}
                        startIcon={<img style={{filter:this.props.location.pathname.startsWith('/flow') ||  (this.props.location.pathname.startsWith('/count') && !this.props.location.pathname.startsWith('/counting'))?"":"invert(100%)"}} src={waveIcon} alt={""}/>}
                        variant={"contained"}
                        href={"/flow/list/all/1"}
                        type={"button"}
                        sx={{color:this.props.location.pathname.startsWith('/flow') ||  (this.props.location.pathname.startsWith('/count') && !this.props.location.pathname.startsWith('/counting'))?"black !important":"white !important"}}
                        color={this.props.location.pathname.startsWith('/flow') ||  (this.props.location.pathname.startsWith('/count') && !this.props.location.pathname.startsWith('/counting'))?"secondary":"primary"}
                >{this.props.t("flow_button")}
                </Button>




                <Button  size={"small"}
                         startIcon={<img style={{filter:this.props.location.pathname.startsWith('/countingRule')?"":"invert(100%)"}} src={countingRuleIcon} alt={""}/>}

                         variant={"contained"}
                         href={"/countingRule/list"}
                         type={"button"}
                         sx={{color:this.props.location.pathname.startsWith('/countingRule')?"black !important":"white !important"}}
                         color={this.props.location.pathname.startsWith('/countingRule')?"secondary":"primary"}
                >{this.props.t("countingRule_button")}
                </Button>




                <Button  size={"small"}
                         startIcon={<img style={{filter:this.props.location.pathname.startsWith('/counting') && !this.props.location.pathname.startsWith('/countingRule')?"":"invert(100%)"}} src={countingIcon} alt={""}/>}
                         href={"/counting/list"}
                         variant={"contained"}
                         sx={{color:(this.props.location.pathname.startsWith('/counting') && !this.props.location.pathname.startsWith('/countingRule')?"black !important":"white !important")}}
                         type={"button"}
                         color={this.props.location.pathname.startsWith('/counting') && !this.props.location.pathname.startsWith('/countingRule')?"secondary":"primary"}
                >{this.props.t("counting_button")}
                </Button>



                <Button size={"small"}
                        startIcon={<img alt={""} style={{marginRight:"6px", filter:this.props.location.pathname.startsWith('/report')?"":"invert(100%)", height:"1.5vh"}} src="/img/icon/JHT design system/Analytics Graph Bar.svg"
                        />}
                        variant={"contained"}
                        href={process.env['REACT_APP_NASTA_REPORT_URL']}
                        type={"button"}
                        target={"_blank"}
                        sx={{color:this.props.location.pathname.startsWith('/report')?"black !important":"white !important"}}
                        color={this.props.location.pathname.startsWith('/report')?"secondary":"primary"}>
                     {this.props.t("reports_button")}
                </Button>


            </nav>
            <hr className="navbar-separator"/>
            </header>
                </>
        )
    }

}

export default withProps(NavBar)