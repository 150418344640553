import {CountingRule} from "./CountingRule";

export class Counting{

    _countingBeginTime;
    _countingEndTime;
    _countingGuid;
    _countingId;
    _countingName;
    _countingRule;
    _createdBy;
    _createdTime;
    _description;
    _error;
    _errorReason;
    _modifiedBy;
    modifiedTime;
    _nonce;
    _ready;
    _visits;

    constructor(countingBeginTime,
                countingEndTime,
                countingGuid,
                countingId,
                countingName,
                countingRule,
                countingRuleId,
                createdBy,
                createdTime,
                description,
                error,
                errorReason,
                modifiedBy,
                modifiedTime,
                nonce,
                ready,
                visits) {

        if(typeof countingBeginTime["countingBeginTime"] !== 'undefined'){

            this._countingBeginTime = countingBeginTime["countingBeginTime"];
            this._countingEndTime = countingBeginTime["countingEndTime"];
            this._countingGuid = countingBeginTime["countingGuid"];
            this._countingId = countingBeginTime["countingId"];
            this._countingName = countingBeginTime["countingName"];
            this._countingRule =(typeof countingBeginTime["countingRule"] !== 'undefined' && countingBeginTime["countingRule"]!==null)? new CountingRule(countingBeginTime["countingRule"]):null;
            this._countingRuleId = countingBeginTime["countingRuleId"];
            this._createdBy = countingBeginTime["createdBy"];
            this._createdTime = countingBeginTime["createdTime"];
            this._description = countingBeginTime["description"];
            this._error = countingBeginTime["error"];
            this._errorReason = countingBeginTime["errorReason"];
            this._modifiedBy = countingBeginTime["modifiedBy"];
            this._modifiedTime = countingBeginTime["modifiedTime"];
            this._nonce = countingBeginTime["nonce"];
            this._ready = countingBeginTime["ready"];
            this._visits = countingBeginTime["visits"];
        }else{
            this._countingBeginTime = countingBeginTime;
            this._countingEndTime = countingEndTime;
            this._countingGuid = countingGuid;
            this._countingId = countingId;
            this._countingName = countingName;
            this._countingRule = countingRule;
            this._countingRuleId = countingRuleId;
            this._createdBy = createdBy;
            this._createdTime = createdTime;
            this._description = description;
            this._error = error;
            this._errorReason = errorReason;
            this._modifiedBy = modifiedBy;
            this._modifiedTime = modifiedTime;
            this._nonce = nonce;
            this._ready = ready;
            this._visits = visits;
        }

    }


    get countingBeginTime() {
        return this._countingBeginTime;
    }

    set countingBeginTime(value) {
        this._countingBeginTime = value;
    }

    get countingEndTime() {
        return this._countingEndTime;
    }

    set countingEndTime(value) {
        this._countingEndTime = value;
    }

    get countingGuid() {
        return this._countingGuid;
    }

    set countingGuid(value) {
        this._countingGuid = value;
    }

    get countingId() {
        return this._countingId;
    }

    set countingId(value) {
        this._countingId = value;
    }

    get countingName() {
        return this._countingName;
    }

    set countingName(value) {
        this._countingName = value;
    }

    get countingRule() {
        return this._countingRule;
    }

    set countingRule(value) {
        this._countingRule = value;
    }

    get countingRuleId() {
        return this._countingRuleId;
    }

    set coutingRuleId(value) {
        this._countingRuleId = value;
    }

    get createdBy() {
        return this._createdBy;
    }

    set createdBy(value) {
        this._createdBy = value;
    }

    get createdTime() {
        return this._createdTime;
    }

    set createdTime(value) {
        this._createdTime = value;
    }

    get description() {
        return this._description;
    }

    set description(value) {
        this._description = value;
    }

    get error() {
        return this._error;
    }

    set error(value) {
        this._error = value;
    }

    get errorReason() {
        return this._errorReason;
    }

    set errorReason(value) {
        this._errorReason = value;
    }

    get modifiedBy() {
        return this._modifiedBy;
    }

    set modifiedBy(value) {
        this._modifiedBy = value;
    }

    get modifiedTime() {
        return this._modifiedTime;
    }

    set modifiedTime(value) {
        this._modifiedTime = value;
    }

    get nonce() {
        return this._nonce;
    }

    set nonce(value) {
        this._nonce = value;
    }

    get ready() {
        return this._ready;
    }

    set ready(value) {
        this._ready = value;
    }

    get visits() {
        return this._visits;
    }

    set visits(value) {
        this._visits = value;
    }
}