import React from "react";
import MultiChoiceSearchDropdown from "../widgets/MultiChoiceSearchDropdown/MultiChoiceSearchDropdown";

import ViewCalcPlace from "./ViewCalcPlace";
import ModalComponent from "../widgets/ModalComponent/ModalComponent";
import ValidatedInputField from "../widgets/ValidatedInputField/ValidatedInputField";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from 'react-i18next';
import {AxiosError} from "axios";
import {
    GetDestinations,
    InserSite,
    UpdateSite,
    DeleteSite,
    GetCodeClasses,
    GetUserCreatedSites,
    SiteDestinationsDeleteAllowed, SiteDeleteAllowed
} from '../utils/NastaApiClient';

import {useSelector, useDispatch, useStore} from 'react-redux';
import * as codesSelector from '../mapViewOl/codesSelector';

import {useLocation, useNavigate} from 'react-router-dom'

import {useParams} from "react-router-dom";
import FormController from "../widgets/FormController/FormController";
import './AddCalcPlace.css'
import close_fill from '../icons/close_fill.svg';
import pencilWriteAlternate from '../icons/Pencil Write Alternate.svg';

import Button from "@mui/material/Button";
import {IconButton, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import UploadingNotification from "../widgets/UploadingNotification/UploadingNotification";

const viewModes = {
    1: "view",
    2: "edit"
}

const withRouter = WrappedComponent => props => {
    const {instance} = useMsal();
    const {t} = useTranslation('addCalcPlace', 'common')
    const location = useLocation();
    const params = useParams();
    const codes = useSelector((state) => state.codes);
    const dispatch = useDispatch();
    const storeHelper = useStore();

    const navigate = useNavigate();

    return (
        <WrappedComponent
            t={t}
            location={location}
            {...props}
            {...{instance, /* other injected props */}}
            params={params}
            navigate={navigate}
            dispatch={dispatch}
            storeHelper={storeHelper}
            codes={codes}
        />
    );
};

class AddCalcPlace extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fetchedBoundData: undefined,
            isSubmitting: false,
            submitAttempted: false,
            submitErrors: [],
            showSubmitModal: false,
            showErrorModal: false,
            showEditingModal: false,
            choices: [],
            currentSearchValue: "",
            viewMode: typeof props !== 'undefined' && typeof props.boundData.viewMode !== 'undefined' ? props.boundData.viewMode : 1,
            editingState: false,
            destinations: [],
            destinationsLoaded: false,
            allValid: false,
            childFlowsWithCounts: [],
            countingsWithSameDestinations: [],
            siteDestinationsHasBeenTouched:false,
            siteDeletionSuccesfulModalVisible:false,
            siteDeletionAllowed: false,
            unRemoveableSiteDestinations:[],
            site: {
                siteId: 0,
                destinations: [],
                coordinateId: null,
                siteName: "",
                responsibleName: null,
                description: null,
                parkDistrictTypeCodeId: null,
                parkDistrictTypeCodeClassId: null,
                entityTypeCodeId: null,
                entityTypeCodeClassId: null,
                landUseUnitTypeCodeId: 1,
                landUseUnitTypeCodeClassId: null,
                landUseUnitName: null,
                regionalUnitTypeCodeId: null,
                regionalUnitTypeCodeClassId: null,
                municipalityCodeId: 0,
                municipalityCodeClassId: 0,
                countyCodeId: 0,
                countyCodeClassId: 0,
                statusCodeId: null,
                statusCodeClassId: null,
                createdBy: "",
                modifiedBy: "",
                coordinate:
                    {
                        coordinateId: 0,
                        x_Coordinate: 0,
                        y_Coordinate: 0,
                        n_Coordinate: 0,
                        e_Coordinate: 0,
                    },
                nonce: ""
            },
            editSuccesfulModalOpen:false
        }

        this.loadData=this.loadData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCoords = this.handleCoords.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.GenQuickMultiSearchDropdown = this.GenQuickMultiSearchDropdown.bind(this);
        this.resetData = this.resetData.bind(this);
        this.GetDestinationSelectionList = this.GetDestinationSelectionList.bind(this);
        this.putDataSourceIntoState = this.putDataSourceIntoState.bind(this);
    }

    validateString(x) {
        if (typeof x === 'string' || x instanceof String) {
            if (x.length < 1) {
                throw new Error("Field length too low (0): " + x)
            }
            return x
        } else {
            throw new TypeError("invalid field value for: " + x + " (string required)")
        }
    }

    validateBoolean(x) {
        if (typeof x == "boolean") {
            return x
        } else {
            throw new TypeError("invalid field value for: " + x + " (boolean required)")
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        /*        if(typeof this.props.boundData.siteName === 'undefined' && this.props.visible===true) {
                    this.props.onVisibilityChanged([false,false]);
                }*/

        /*      if(this.props.boundData.visibility===false && this.props.visible===true){
                  this.props.onVisibilityChanged([false,false]);
              }*/

        /*        if(typeof this.props.boundData.viewMode!=='undefined'){
                    if(this.props.boundData.viewMode!==this.state.viewMode){
                        this.setState({viewMode: this.props.boundData.viewMode})
                    }
                }*/

    }

    async componentDidMount() {
        await this.loadData();
        let isDeleteAllowed = await this.checkSiteDeleteAllowed();
        this.setState({siteDeletionAllowed: isDeleteAllowed});
    }



    async loadData(){
        if (typeof this.props.params.id !== 'undefined') {


            GetUserCreatedSites().then(x => {

                    let targetSite = x.data.find(f => f.properties.siteId === parseInt(this.props.params.id));

                    this.setState({fetchedBoundData: targetSite.properties}, async()=>{


                        let targList = targetSite.properties.destinations.map(x=>x.Id)
                        await SiteDestinationsDeleteAllowed(targList, this.props.params.id).then(resp=>{


                            let tmpUnRemoveableDestinations = [];
                            let res = resp.data;
                            for (let i = 0; i < targList.length; i++) {
                                if(res[i]===!targList[i]){
                                    tmpUnRemoveableDestinations.push(targList[i]);
                                }
                            }
                           this.setState({unRemoveableSiteDestinations: tmpUnRemoveableDestinations});
                        })

                    });
                }
            )
        }else{
            let dataSource = (typeof this.state.fetchedBoundData !== 'undefined') ? this.state.fetchedBoundData : this.props.boundData;




            if(typeof dataSource["destinations"]!=='undefined'){
                let targList = dataSource["destinations"].map(x=>x.Id)
                await SiteDestinationsDeleteAllowed(targList, dataSource["siteId"]).then(resp=>{


                    let tmpUnRemoveableDestinations = [];
                    let res = resp.data;
                    for (let i = 0; i < targList.length; i++) {
                        if(!res[i]===!targList[i]){
                            tmpUnRemoveableDestinations.push(targList[i]);
                        }
                    }
                    this.setState({unRemoveableSiteDestinations: tmpUnRemoveableDestinations});
                })
            }

        }



        this.setState({site: {...this.state.site, nonce: crypto.randomUUID()}});
        this.setState(state => {
            state.site.createdBy = typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '';
            state.site.modifiedBy = typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '';
            return state
        });

        try {
            if (!this.props.storeHelper.getState().codesSelector.codes.codeClassesLoaded) {
                let response = await GetCodeClasses();

                if (response instanceof AxiosError) {
                    this.setState({error: response});
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(false));
                } else {
                    this.props.dispatch(codesSelector.fillCodeClasses(response));
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(true));
                }
            }
        } catch (error) {
            console.log(error);
        }
        try {
            let response = await GetDestinations();
            if (response instanceof AxiosError) {
                this.setState({error: response});
                this.setState({destinationsLoaded: false});
            } else {
                if (response.length > 0) {
                    this.setState({destinations: response});
                    this.setState({destinationsLoaded: true});
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleSelection(choiceList, codeClass) {
        //TODO: if in future multiple selection allowed for these rethink this
        switch (codeClass.name) {

            case 'municipality':
                this.setState(state => {
                    state.site.municipality = choiceList[0].id
                    state.site.municipalityCodeClassId = codeClass.codeClassId
                    return state
                });
                break;
            case 'county':
                this.setState(state => {
                    state.site.county = choiceList[0].id
                    state.site.countyCodeClassId = codeClass.codeClassId
                    return state
                });
                break;
            case 'status':
                this.setState(state => {
                    state.site.statusCodeId = choiceList[0].id
                    state.site.statusCodeClassId = codeClass.codeClassId
                    return state
                });
                break;
            default:
                console.log(`Not found ${codeClass.codeClassId}.`);
        }
    }

    async handleSubmit() {

        this.setState({isSubmitting: true});
        this.setState({submitAttempted: true});


        if (this.state.allValid === false) {
            this.setState({submitErrors: [this.props.t("common:one_or_more_required_field_is_invalid")]})
            return false;
        }

        if (!this.state.editingState) {


            let submitThis = this.state.site;
            submitThis.coordinate.x_Coordinate = typeof this.props.boundData.coordinate[0] !== 'undefined' ? this.props.boundData.coordinate[0] : -1;
            submitThis.coordinate.y_Coordinate = typeof this.props.boundData.coordinate[1] !== 'undefined' ? this.props.boundData.coordinate[1] : -1;
            submitThis.coordinate.n_Coordinate = typeof this.props.boundData.latLon[1] !== 'undefined' ? this.props.boundData.latLon[1] : -1;
            submitThis.coordinate.e_Coordinate = typeof this.props.boundData.latLon[0] !== 'undefined' ? this.props.boundData.latLon[0] : -1;
            submitThis.createdBy = typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '';
            submitThis.modifiedBy = typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '';
            submitThis.municipalityCodeClassId = 280;
            submitThis.municipalityCodeId = typeof this.props.boundData.munCodeId !== 'undefined' ? this.props.boundData.munCodeId : -1;
            submitThis.countyCodeClassId = 1005;
            submitThis.countyCodeId = typeof this.props.boundData.cCodeId !== 'undefined' ? this.props.boundData.cCodeId : -1;
            submitThis.regionalUnitTypeCodeId = typeof this.props.boundData.regionalUnitTypeCodeId !== 'undefined' ? this.props.boundData.regionalUnitTypeCodeId : -1;
            submitThis.regionalUnitTypeCodeClassId = 350;
            submitThis.parkDistrictTypeCodeId = typeof this.props.boundData.parkDistrictTypeCodeId !== 'undefined' ? this.props.boundData.parkDistrictTypeCodeId : -1;
            submitThis.parkDistrictTypeCodeClassId = 1004;
            submitThis.entityTypeCodeId = typeof typeof this.props.boundData.entityTypeCodeId !== 'undefined' ? this.props.boundData.entityTypeCodeId : -1;
            submitThis.entityTypeCodeClassId = 130;
            submitThis.landUseUnitTypeCodeId = 1;
            submitThis.landUseUnitTypeCodeClassId = 1011;
            submitThis.landUseUnitName = typeof this.props.boundData.landUseUnitName !== 'undefined' ? this.props.boundData.landUseUnitName : this.state.landUseUnitName
            submitThis.landUseUnitId = typeof this.props.boundData.landUseUnitId !== 'undefined' ? this.props.boundData.landUseUnitId : -1;
            submitThis.description = typeof this.props.boundData.description !== 'undefined' ? this.props.boundData.description : (this.state.site.description!==null?this.state.site.description:"");
            submitThis.responsibleName = (typeof this.props.boundData.responsibleName !== 'undefined' && this.props.boundData.responsibleName!=='') ? this.props.boundData.responsibleName : (this.state.site.responsibleName!==null?this.state.site.responsibleName:"");
            submitThis.siteTypeCodeId = 1;
            submitThis.siteTypeCodeClassId = 1006;

            let response = await InserSite(submitThis);

            if (response instanceof AxiosError) {
                this.props.onSubmit("Warning", "Sending failed: " + response);
                this.setVisibility(true);
            } else {
               // this.props.onSubmit("Success", "Success");
                this.setVisibility(false)
            }
        } else {

            let dataSource = (typeof this.state.fetchedBoundData !== 'undefined') ? this.state.fetchedBoundData : this.props.boundData;
            let updateModel = {};
            let updateSiteDestination = [];

            if (this.state.site.destinations !== null) {

                let e1 = true;
                for (let i = 0; i < this.state.site.destinations.length; i++) {

                    let dItem = dataSource["destinations"].find(f => f.id === this.state.site.destinations[i].id)

                    if(dItem===undefined){
                        if(this.state.site.destinations.length>0){
                            updateSiteDestination = this.state.site.destinations;

                            break;
                        }

                    }
                }
            }

            if (this.state.site.siteName !== null && this.state.site.siteName !== '' && dataSource["siteName"] !== this.state.site.siteName) {
                updateModel = {...updateModel, siteName: this.state.site.siteName};
            }

            if (this.state.site.responsibleName !== null && dataSource["responsibleName"] !== this.state.site.responsibleName) {
                updateModel = {...updateModel, responsibleName: this.state.site.responsibleName};
            }

            if (this.state.site.description !== null && dataSource["description"] !== this.state.site.description) {
                updateModel = {...updateModel, description: this.state.site.description};
            }

            if (this.state.site.landUseUnitName !== null && dataSource["landUseUnitName"] !== this.state.site.landUseUnitName) {
                updateModel = {...updateModel, landUseUnitName: this.state.site.landUseUnitName};
            }


            if (this.state.site.statusCodeId !== null && this.state.site.statusCodeClassId && dataSource["statusCodeId"] !== this.state.site.statusCodeId) {
                updateModel = {
                    ...updateModel,
                    statusCodeId: this.state.site.statusCodeId,
                    statusCodeClassId: this.state.site.statusCodeClassId
                }
            }


            updateModel = {
                ...updateModel,
                modifiedBy: this.state.site.modifiedBy
            }

            if(updateSiteDestination.length>0 || this.state.siteDestinationsHasBeenTouched){
                updateModel={
                    ...updateModel,
                    updateSiteDestination:  updateSiteDestination,
                }
            }

            let response = await UpdateSite(dataSource["siteId"], updateModel);

            if (response instanceof AxiosError) {
                this.props.onSubmit("Warning", "Sending failed: " + response);
                this.setState({errors: [response]});
                if (typeof this.props.boundData.loadSites !== 'undefined') {
                    this.setVisibility(true);
                }

            } else {
                //this.props.onSubmit("Success", "Success");
                if (typeof this.props.boundData.loadSites !== 'undefined') {
                    this.setVisibility(false);
                }
            }

            this.setState({editingState: false});
        }
        this.setState({isSubmitting: false});


        if (typeof this.props.boundData.loadSites !== 'undefined') {
            await this.props.boundData.loadSites();
            this.props.boundData.clearMapSelection();
        } else {
            localStorage.setItem("calcPlaceListShouldReload", true);
            await this.loadData();
            this.setState({editSuccesfulModalOpen:true, isSubmitting:false});
            //this.props.navigate(-1);
        }
    }


    async deleteSite() {
        if (typeof this.state.site.siteName !== 'undefined' || typeof this.props.boundData.siteId !== 'undefined' && this.props.boundData.siteId !== null) {
            let response;
            if (typeof this.state.site.siteName !== 'undefined') {
                if(typeof this.state.fetchedBoundData !== 'undefined'){
                    response = await DeleteSite(this.state.fetchedBoundData.siteId);
                }else{
                    response = await DeleteSite(this.props.boundData.siteId);
                }

            } else {
                response = await DeleteSite(this.props.boundData.siteId);
            }

            if (response instanceof AxiosError) {
                this.props.onSubmit("Warning", "Sending failed: " + response);
                this.setState({errors: [response]});
                if (typeof this.state.site.siteName !== 'undefined') {
                    this.props.navigate(-1);
                } else {
                    this.setVisibility(true);
                }

            } else {
               // this.props.onSubmit("Success", "Success");
                    this.setState({siteDeletionSuccesfulModalVisible: true}
                );
            }
        }
    }

    handleChange(event, validationFunction) {
        const newTargetValue = validationFunction(event.target.value);

        this.setState({[event.target.name]: newTargetValue});
    }

    handleCoords(event) {
        const handler = new RegExp("^(?!\\s*$)?[[0-9]*[.,]?[0-9]*$");
        if (handler.test(event.target.value)) {
            let coords = this.state.coordinates;
            coords[event.target.name] = event.target.value.toString().replace(",", ".");
            this.setState({coordinates: coords})

        } else {
            throw new TypeError("Coordinate not solvable to float");
        }
    }


    GenQuickMultiSearchDropdown(props) {
        let {t} = this.props;

        let codeClass = this.props.storeHelper.getState().codesSelector.codes.codeClasses.find(cc => cc.name === props.dataName);

        if (typeof codeClass !== 'undefined') {
            let selectedItem = undefined;
            let choiceArray = codeClass.codes.map(code => {
                if (typeof code.languages[code.languages.findIndex(i => i.languageName === this.props.t('common:GMLLanguage'))] !== 'undefined' && typeof code.codeId !== 'undefined') return {
                    id: code.codeId,
                    name: code.languages[code.languages.findIndex(i => i.languageName === this.props.t('common:GMLLanguage'))].text,
                    title: code.codeId.toString()
                }
                else {
                    return {
                        id: -1,
                        name: 'not named',
                        title: '-1'
                    }
                }
            });

            if (typeof props.selectedCodeId !== 'undefined') {
                selectedItem = choiceArray[choiceArray.findIndex(i => i.id === parseInt(props.selectedCodeId))];
            }

            return (
                <MultiChoiceSearchDropdown required={props.required}
                                           maxChoices={typeof props.maxChoices !== 'undefined' ? props.maxChoices : 1}
                                           active={props.active}
                                           minChoicesFixed={typeof props.minChoicesFixed ? props.minChoicesFixed : false}
                                           minChoices={1}
                                           choices={props.dataLocation}
                                           validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                           title={this.props.t('addCalcPlace:' + props.title)}
                                           tooltip={this.props.t('addCalcPlace:' + props.tooltip)}

                                           inputData={choiceArray}
                                           defaultSelected={typeof selectedItem !== 'undefined' && selectedItem !== null ? [selectedItem] : []}
                                           onChoicesChange={(choicesList) => {
                                               if (choicesList.length > 0) {
                                                   this.handleSelection(choicesList, codeClass)
                                               }
                                           }}
                                           onValidityChange={props.onValidityChange}

                />
            )
        } else {
            return (<>
                <label className="form-label"
                       htmlFor={props.dataName}>{this.props.t('addCalcPlace:' + props.title)}


                    <Tooltip title={props.tooltip}>
                        <IconButton>
                            <InfoIcon/>

                        </IconButton>
                    </Tooltip>
                </label>
                <input className="form-input" id={props.dataName}
                       placeholder={"Missing codeClass data for " + this.props.t('addCalcPlace:' + "placeholder_" + props.dataName)}></input></>)
        }
    }

    GetDestinationSelectionList(props) {

        let destinations = (typeof this.state.fetchedBoundData !== 'undefined') ? this.state.fetchedBoundData.destinations : this.props.boundData.destinations;

        if (typeof this.state !== 'undefined') {
            let listOfActiveDestinations = [];
            if (this.state.destinations.length > 0) {
                listOfActiveDestinations = this.state.destinations.map(x => {
                    
                        return {
                            id: x.destinationId.toString(),
                            name: x.destinationName,
                            title: x.destinationId.toString(),
                            icon: "chip"
                        }
                    
                }).sort((a,b)=>parseInt(a.destinationId)<parseInt(b.destinationId)?1:-1);
            }

            let destinationSelection = [];
            if (typeof destinations !== 'undefined') {
                destinationSelection = destinations.map(x => {
                    {
                        return {id: x.Id.toString(), name: x.Name, title: x.Title, icon: x.Icon}
                    }
                });
            }

            return <MultiChoiceSearchDropdown
                required={true}
                maxChoices={listOfActiveDestinations.length}
                active={true}
                minChoices={1}
                disabledChoices={this.state.unRemoveableSiteDestinations}
                title={this.props.t('addCalcPlace:destination_id')}
                tooltip={this.props.t('addCalcPlace:tooltip_destination_id')}
                placeholder={this.props.t('addCalcPlace:placeholder_destination_id')}
                inputData={listOfActiveDestinations}
                validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                defaultSelected={destinationSelection}
                onChoicesChange={(choicesList) => {

                    this.setState({site: {...this.state.site, destinations: choicesList}, siteDestinationsHasBeenTouched:true})


                }}
                choiceAttrsShown={['id', 'name']}
                onValidityChange={props.onValidityChange}

            />
        }
    }

    setVisibility(visibility) {
        this.props.onVisibilityChanged([visibility, true]);
    }

    resetData() {
        this.setState({
            submitAttempted: false,
            site: {
                ...this.state.site,
                siteId: 0,
                destinationId: 0,
                coordinateId: 0,
                regionId: 0,
                paveSiteId: 0,
                paveSiteEntityId: 0,
                siteIdEcoVision: 0,
                siteName: "",
                responsibleName: "",
                description: "",
                statusCodeId: 0,
                statusCodeClassId: 0,
                createdTime: "",
                modifiedTime: "",
            }
        })
    }

    async checkSiteDeleteAllowed(){
        let dataSource = (typeof this.state.fetchedBoundData !== 'undefined') ? this.state.fetchedBoundData : this.props.boundData;
        let canDelete = dataSource["siteId"] !== undefined && await SiteDeleteAllowed(dataSource["siteId"]);

        if(canDelete?.data===true){
            return true;
        }
        return false;
    }
    putDataSourceIntoState() {
        let dataSource = (typeof this.state.fetchedBoundData !== 'undefined') ? this.state.fetchedBoundData : this.props.boundData;
        this.setState({
            site: {
                ...this.state.site,
                siteName: dataSource["siteName"],
                responsibleName: dataSource["responsibleName"],
                description: dataSource["description"]
            }
        })
    }

    render() {
        let {t} = this.props;
        console.log('PROPS ', this.props.t('addCalcPlace:site_name'));
        let dataSource = (typeof this.state.fetchedBoundData !== 'undefined') ? this.state.fetchedBoundData : this.props.boundData;

        return (
            <>

                 <ModalComponent
                    showModal={this.state.editSuccesfulModalOpen}
                    title={t("edit_succesful_title")}
                    content={t("edit_succesful_content")}
                    modalType={"errorModal"}
                    onModalContinue={() => {
                        this.setState({viewMode: 1, editSuccesfulModalOpen:false,loadRecentSiteFromState:true}, async ()=>{

                        });
                    }}

                />

                <ModalComponent
                    showModal={this.state.siteDeletionSuccesfulModalVisible}
                    title={t("site_delete_succesful_title")}
                    content={t("site_delete_succesful_content")}
                    modalType={"errorModal"}
                    onModalContinue={async () => {

                        if(typeof this.props.boundData.siteId === 'undefined'){

                        }else{
                            this.setVisibility(false);
                        }

                        if(this.props.location.pathname.startsWith('/site/view')){
                            this.props.navigate('/site/list'); 
                            this.setState({siteDeletionSuccesfulModalVisible:false});
                        }else{    
                            //this.props.navigate('/sites/add'); 
                            this.setVisibility(false);
                            this.props.boundData.centerMap();
                           
                            this.setState({siteDeletionSuccesfulModalVisible:false});
                            if (typeof this.props.boundData.loadSites !== 'undefined' && this.props.boundData.loadSites !== null
                                && this.props.boundData.clearMapSelection !== 'undefined' && this.props.boundData.clearMapSelection !== null) {
                                    await this.props.boundData.loadSites();
                                    this.props.boundData.clearMapSelection();
                                }
                        }
                    }}
                />

                <ModalComponent showModal={this.state.showErrorModal}
                                title={t("delete_site_button")}
                                content={t("delete_site_description")}
                                modalType={"submitModal"}
                                onModalContinue={async () => {
                                    this.setState({showErrorModal: false,isSubmitting:true},async  ()=>{
                                            await this.deleteSite();
                                            this.setState({showErrorModal: false, siteDeletionSuccesfulModalVisible:true})
                                    });

                                }}
                                onModalCancel={() => {
                                    this.setState({showErrorModal: false})
                                }}
                />

                <ModalComponent showModal={this.state.showEditingModal && this.state.isSubmitting === false}
                                title={t('title_editingModal')}
                                content={t('content_editingModal')}
                                modalType={"submitModal"}
                                onModalContinue={async () => {
                                    await this.handleSubmit();
                                    this.setState({showEditingModal: false});

                                }}
                                onModalCancel={() => {
                                    this.setState({showEditingModal: false})
                                }}
                />


                {this.state.viewMode === 1 ? <>
                    <div style={{marginBottom: "2em"}}>

                        {typeof this.props.location !== 'undefined' && !this.props.location.pathname.startsWith('/site/view') ? <>
                            <Button size={"small"} variant={"contained"} className="float-right btn-no-style"
                                    onClick={() => {
                                        this.setState({isSubmitting:false}, ()=>{
                                             this.setVisibility(false);
                                        })
                                        if (!(Object.keys(this.props.boundData).length === 0 && this.props.boundData.constructor === Object)) {
                                            this.props.boundData.clearMapSelection();
                                            this.setVisibility(false)
                                        }
                                    }}>
                                <img alt={""} src={close_fill}
                                     disabled={localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" ? false : true}

                                     id="close-add-site"/></Button> </> : <></>
                        }

                        <Button aria-label={t("common:close")} size={"small"} variant={"contained"}
                                className="float-left"
                                disabled={localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" ? false : true}
                                onClick={() => {
                                    this.putDataSourceIntoState();
                                    this.setState({viewMode: 2, editingState: true})
                                }}><img style={{filter: "invert(100%)", height: "1.5vh"}} src={pencilWriteAlternate}
                                        alt={t("common:button_edit")}/>{t("button_edit")}
                        </Button>
                    </div>
                    <ViewCalcPlace {...(Object.keys(this.props.boundData).length === 0 ? this.state.fetchedBoundData : this.props.boundData)}/>
                </> : <>

                    <Button aria-label={t('common:close')} size={"small"} variant={"contained"}
                            className="float-right btn-no-style" onClick={() => {

                        if (this.props.location.pathname.startsWith('/site/view')) {
                            this.setState({viewMode: 1});
                        } else {
                            this.props.boundData.clearMapSelection();
                            this.setVisibility(false);
                        }

                    }}>


                        <img alt={""} src={close_fill}
                             disabled={localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" ? false : true}

                             id="close-add-site"/>
                    </Button>

                    <form onSubmit={this.handleSubmit}>


                        {
                            (this.props.storeHelper.getState().codesSelector.codes.codeClassesLoaded === true && this.state.destinationsLoaded === true) ? <>

                                <FormController onValidityChanged={(validity) => this.setState({allValid: validity})}>
                                <div className="form-group">
                                    <label htmlFor={"destinationId"} className="form-label">{t("addCalcPlace:site_id")}

                                        {(typeof this.props.t('addCalcPlace:tooltip_site_id') !== 'undefined'
                                                && this.props.t('addCalcPlace:tooltip_site_id') !== null
                                                && this.props.t('addCalcPlace:tooltip_site_id') !== '') &&


                                            <Tooltip title={this.props.t('addCalcPlace:tooltip_site_id')}>
                                                <IconButton>
                                                    <InfoIcon/>

                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </label>

                                    <p id={"destinationId"}>
                                        {typeof dataSource !== 'undefined' && typeof dataSource["siteId"] !== 'undefined' ? dataSource["siteId"] : ''}
                                    </p>
                                </div>

                                <div className="form-group">
                                    <label htmlFor={"paVeSiteId"}
                                           className="form-label">{t("addCalcPlace:pave_site_id")}

                                        {(typeof this.props.t('addCalcPlace:pave_site_id') !== 'undefined'
                                                && this.props.t('addCalcPlace:pave_site_id') !== null
                                                && this.props.t('addCalcPlace:pave_site_id') !== '') &&
                                            <Tooltip title={this.props.t('addCalcPlace:pave_site_id')}>
                                                <IconButton>
                                                    <InfoIcon/>

                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </label>

                                    <p id={"paVeSiteId"}>
                                        {typeof dataSource !== 'undefined' && typeof dataSource["paVeSiteId"] !== 'undefined' ? dataSource["paVeSiteId"] : ''}
                                    </p>
                                </div>

                                <div className="form-group">
                                    <label htmlFor={"ecoVisionId"}
                                           className="form-label">{t("addCalcPlace:ecovision_id")}

                                        {(typeof this.props.t('addCalcPlace:ecovision_id') !== 'undefined'
                                                && this.props.t('addCalcPlace:ecovision_id') !== null
                                                && this.props.t('addCalcPlace:ecovision_id') !== '') &&
                                            <Tooltip title={this.props.t('addCalcPlace:ecovision_id')}>
                                                <IconButton>
                                                    <InfoIcon/>

                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </label>

                                    <p id={"ecoVisionId"}>
                                        {typeof dataSource !== 'undefined' && typeof dataSource["siteIdEcoVision"] !== 'undefined' ? dataSource["ecoVisionId"] : ''}
                                    </p>
                                </div>

                               
                                    <ValidatedInputField
                                        name={"siteName"}
                                        fieldName={this.props.t('addCalcPlace:site_name')}
                                        tooltip={this.props.t('addCalcPlace:tooltip_site_name')}
                                        placeholder={this.props.t('addCalcPlace:placeholder_site_name')}
                                        required={true}
                                        minLength={2}
                                        maxLength={100}
                                        type={"text"}
                                        fieldId={'siteName'}
                                        validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                        value={this.state.site.siteName}
                                        validatorFunction={() => {
                                            return true
                                        }}
                                        onFieldValueChanged={(newValue) => {

                                            this.setState({site: {...this.state.site, siteName: newValue}})
                                        }}
                                        onFieldValidityChanged={(validity) => {
                                            this.setState({siteValid: {...this.state.siteValid, siteName: validity}})
                                        }}
                                    />

                                    <this.GetDestinationSelectionList/>

                                    <this.GenQuickMultiSearchDropdown required={true} active={true} dataName="status"
                                                                      placeholder={"placeholder_status"}
                                                                      tooltip={"tooltip_status"} title={"status"}
                                                                      selectedCodeId={typeof dataSource["statusCodeId"] !== 'undefined' ? dataSource["statusCodeId"] : undefined}/>

                                    <ValidatedInputField
                                        name={"responsibleName"}
                                        fieldName={this.props.t('addCalcPlace:responsible_name')}
                                        tooltip={this.props.t('addCalcPlace:tooltip_responsible_name')}
                                        placeholder={this.props.t('addCalcPlace:placeholder_responsible_name')}
                                        required={false}
                                        minLength={0}
                                        maxLength={100}
                                        type={"text"}
                                        fieldId={'responsibleName'}
                                        validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                        value={this.state.site.responsibleName}
                                        validatorFunction={() => {
                                            return true
                                        }}
                                        onFieldValueChanged={(newValue) => {
                                            this.setState({site: {...this.state.site, responsibleName: newValue}})
                                        }}
                                        onFieldValidityChanged={(validity) => {
                                            this.setState({
                                                siteValid: {
                                                    ...this.state.siteValid,
                                                    responsibleName: validity
                                                }
                                            })
                                        }}
                                    />

                                    <ValidatedInputField
                                        name={"description"}
                                        fieldName={this.props.t('addCalcPlace:additional_info')}
                                        tooltip={this.props.t('addCalcPlace:tooltip_additional_info')}
                                        placeholder={this.props.t('addCalcPlace:placeholder_additional_info')}
                                        required={false}
                                        minLength={0}
                                        maxLength={1000}
                                        multiline={true}
                                        type={"text"}
                                        fieldId={'description'}
                                        validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                        value={this.state.site.description}
                                        validatorFunction={() => {
                                            return true
                                        }}
                                        onFieldValueChanged={(newValue) => {
                                            this.setState({site: {...this.state.site, description: newValue}})
                                        }}
                                        onFieldValidityChanged={(validity) => {
                                            this.setState({siteValid: {...this.state.siteValid, description: validity}})
                                        }}
                                    />

                                    <ValidatedInputField
                                        name={"landUseUnitName"}
                                        fieldName={this.props.t('addPlace:land_use_unit_name')}
                                        tooltip={this.props.t('addPlace:tooltip_land_use_unit_name')}
                                        placeholder={this.props.t('addPlace:placeholder_land_use_unit_name')}
                                        required={false}
                                        minLength={1}
                                        maxLength={100}
                                        type={"text"}
                                        disabled={true}
                                        fieldId={'landUseUnitId'}
                                        validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                        value={this.state.site.landUseUnitName !== null ? this.state.site.landUseUnitName : dataSource["landUseUnitName"]}
                                        validatorFunction={() => {
                                            return true
                                        }}
                                        onFieldValueChanged={(newValue) => {

                                            this.setState({site: {...this.state.site, landUseUnitName: newValue}})
                                        }}
                                    />
                                    <this.GenQuickMultiSearchDropdown
                                        required={false}
                                        minChoicesFixed={true}
                                        active={false}
                                        dataName="landUseUnitType"
                                        placeholder={"placeholder_land_use_unit_type"}
                                        tooltip={"tooltip_land_use_unit_type"} title={"land_use_unit_type"}
                                        selectedCodeId={typeof dataSource !== 'undefined' && typeof dataSource["landUseUnitTypeCodeId"] !== 'undefined' && dataSource["landUseUnitTypeCodeId"] !== -1 ? dataSource["landUseUnitTypeCodeId"] : ''}/>
                                    <this.GenQuickMultiSearchDropdown
                                        required={false}
                                        minChoicesFixed={this.state.editingState || typeof dataSource["entityTypeCodeId"] !== 'undefined'}
                                        active={this.state.editingState} dataName="entity"
                                        placeholder={"placeholder_entity"} tooltip={"tooltip_entity"} title={"entity"}
                                        selectedCodeId={typeof dataSource !== 'undefined' && typeof dataSource["entityTypeCodeId"] !== 'undefined' ? dataSource["entityTypeCodeId"] : -1}/>
                                    <this.GenQuickMultiSearchDropdown
                                        required={false}
                                        minChoicesFixed={this.state.editingState || typeof dataSource["parkDistrictTypeCodeId"] !== 'undefined'}
                                        active={this.state.editingState} dataName="parkDistrict"
                                        placeholder={"placeholder_park_district"} tooltip={"tooltip_park_district"}
                                        title={"park_district"}
                                        selectedCodeId={typeof dataSource !== 'undefined' && typeof dataSource["parkDistrictTypeCodeId"] !== 'undefined' ? dataSource["parkDistrictTypeCodeId"] : -1}/>
                                    <this.GenQuickMultiSearchDropdown
                                        required={true}
                                        dataName={"regionalUnit"}
                                        minChoicesFixed={this.state.editingState || typeof dataSource["regionalUnitTypeCodeId"] !== 'undefined'}
                                        active={this.state.editingState}
                                        placeholder={"placeholder_regional_unit"} tooltip={"tooltip_regional_unit"}
                                        title={"regional_unit"}
                                        selectedCodeId={typeof dataSource !== 'undefined' && typeof dataSource["regionalUnitTypeCodeId"] !== 'undefined' ? dataSource["regionalUnitTypeCodeId"] : -1}/>
                                    <this.GenQuickMultiSearchDropdown
                                        required={true}
                                        minChoicesFixed={this.state.editingState || typeof dataSource["munCodeId"] !== 'undefined'}
                                        active={this.state.editingState} dataName="municipality"
                                        placeholder={"placeholder_municipality"} tooltip={"tooltip_municipality"}
                                        title={"municipality"}
                                        selectedCodeId={typeof dataSource !== 'undefined' && typeof dataSource["munCodeId"] !== 'undefined' ? dataSource["munCodeId"] : dataSource["municipalityCodeId"]}/>
                                    <this.GenQuickMultiSearchDropdown
                                        required={true}
                                        minChoicesFixed={this.state.editingState || typeof dataSource["cCodeId"] !== 'undefined'}
                                        active={this.state.editingState} dataName="county"
                                        placeholder={"placeholder_county"} tooltip={"tooltip_county"} title={"county"}
                                        selectedCodeId={typeof dataSource !== 'undefined' && typeof dataSource["cCodeId"] !== 'undefined' ? dataSource["cCodeId"] : dataSource["countyCodeId"]}/>

                            </FormController> </> : <>
                            </>
                        }

                        <div className="form-group">
                            <label className="form-label" htmlFor="x_Coordinate">{t("addCalcPlace:coordinate_X")}

                                {(typeof this.props.t('addCalcPlace:coordinate_X') !== 'undefined'
                                        && this.props.t('addCalcPlace:coordinate_X') !== null
                                        && this.props.t('addCalcPlace:coordinate_X') !== '') &&
                                    <Tooltip title={this.props.t('addCalcPlace:tooltip_coordinate_X')}>
                                        <IconButton>
                                            <InfoIcon/>

                                        </IconButton>
                                    </Tooltip>
                                }
                            </label>

                            <p id={"x_Coordinate"}>{typeof dataSource !== 'undefined' && typeof dataSource["coordinate"] !== 'undefined' && typeof dataSource["coordinate"][0] !== 'undefined' ? parseInt(dataSource["coordinate"][0]) : typeof dataSource["coordinate"] !== 'undefined' ? parseInt(dataSource["coordinate"].x_Coordinate) : ''}</p>

                            <label className="form-label"
                                   htmlFor="coordinate_Y">{t("addCalcPlace:coordinate_Y")}

                                <Tooltip title={this.props.t('addCalcPlace:tooltip_coordinate_Y')}>
                                    <IconButton>
                                        <InfoIcon/>

                                    </IconButton>
                                </Tooltip>

                            </label>

                            <p id={"coordinate_Y"}>{typeof dataSource !== 'undefined' && typeof dataSource["coordinate"] !== 'undefined' && typeof dataSource["coordinate"][1] !== 'undefined' ? parseInt(dataSource["coordinate"][1]) : typeof dataSource["coordinate"] !== 'undefined' ? parseInt(dataSource["coordinate"].y_Coordinate) : ''}</p>


                            <label className="form-label" htmlFor="coordinate_N">{t("addCalcPlace:coordinate_N")}
                                <Tooltip title={this.props.t('addCalcPlace:tooltip_coordinate_N')}>
                                    <IconButton>
                                        <InfoIcon/>

                                    </IconButton>
                                </Tooltip>

                            </label>

                            <p id={"coordinate_N"}>{typeof dataSource !== 'undefined' && typeof dataSource["latLon"] !== 'undefined' ? parseFloat(dataSource["latLon"][1]).toFixed(8) : typeof dataSource["coordinate"] !== 'undefined' ? parseFloat(dataSource["coordinate"].n_Coordinate).toFixed(8) : ''}</p>


                            <label className="form-label" htmlFor="coordinate_E">{t("addCalcPlace:coordinate_E")}
                                <Tooltip title={this.props.t('addCalcPlace:tooltip_coordinate_E')}>
                                    <IconButton>
                                        <InfoIcon/>

                                    </IconButton>
                                </Tooltip>

                            </label>
                            <p id={"coordinate_E"}>{typeof dataSource !== 'undefined' && typeof dataSource["latLon"] !== 'undefined' ? parseFloat(dataSource["latLon"][0]).toFixed(8) : typeof dataSource["coordinate"] !== 'undefined' ? parseFloat(dataSource["coordinate"].e_Coordinate).toFixed(8) : ''}</p>

                        </div>


                        <div className="form-group">
                            <label className="form-label" htmlFor={"siteCreator"}>{t('addCalcPlace:site_creator')}
                                <Tooltip title={this.props.t('addCalcPlace:site_creator')}>
                                    <IconButton>
                                        <InfoIcon/>

                                    </IconButton>
                                </Tooltip>

                            </label>

                            <p id={"siteCreator"}>{typeof dataSource !== 'undefined' && typeof dataSource["createdBy"] !== 'undefined' ? dataSource["createdBy"] : typeof this.props.instance.getAllAccounts() !== 'undefined' && this.props.instance.getAllAccounts().length > 0 ? this.props.instance.getAllAccounts()[0].name : ''}</p>
                        </div>


                        <div className="form-group">
                            <label className="form-label"
                                   htmlFor={"siteModifier"}>{t('addCalcPlace:site_modifier')}

                                <Tooltip title={this.props.t('addCalcPlace:site_modifier')}>
                                    <IconButton>
                                        <InfoIcon/>

                                    </IconButton>
                                </Tooltip>
                            </label>

                            <p id={"siteModifier"}>{typeof this.props.instance.getAllAccounts() !== 'undefined' && this.props.instance.getAllAccounts().length > 0 ? this.props.instance.getAllAccounts()[0].name : ''}</p>

                        </div>
                        <div>

                        {this.state.viewMode === 2 && typeof dataSource !== 'undefined' && typeof dataSource["siteId"] !== 'undefined' &&
                            <Button size={"small"} variant={"contained"} type="button"
                                    onClick={() => this.setState({showErrorModal: true})}
                                    disabled={(localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser") && this.state.siteDeletionAllowed === true ? false : true}
                                    className="float-left"><img style={{filter: "invert(100%)", height: "1.5vh"}}
                                                                src="/img/icon/JHT design system/Pencil Write Alternate.svg"
                                                                alt={t("common:button_edit")}/>{t("addCalcPlace:button_delete")}
                            </Button>}


                            <Button size={"small"} variant={"contained"} type="button"
                                    disabled={localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" ? false : true}
                                    className="float-right" onClick={async () => {
                                if (this.state.editingState) {
                                    this.setState({showEditingModal: true})
                                } else {
                                    await this.handleSubmit();
                                }

                            }}><img alt={""} style={{filter: "invert(100%)", height: "1.5vh"}}
                                    src="/img/icon/JHT design system/check_fill.svg"/> {t("addCalcPlace:button_save")}
                            </Button>

                            {this.state.isSubmitting && <UploadingNotification showModal={this.state.isSubmitting}
                                                                               errors={this.state.submitErrors}
                                                                               type={"upload"}/>}
                        </div>


                    </form>

                </>

                }

            </>
        )
    }
}

export default withRouter(AddCalcPlace);