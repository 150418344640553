import React from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import {View} from "ol";
import moment from "moment-timezone/moment-timezone-utils";
import {GetCount, GetFlow} from "../utils/NastaApiClient";
import Count from "../dataClasses/Count";
import {Flow} from "../dataClasses/Flow";
import {IconButton, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const {t} = useTranslation('count', 'common')

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
            params={params}
            navigate={navigate}
        />
    );
};

class ViewCount extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allLoaded:false,
            /**
             *
             * @type {Flow}
             */
            flow:null,
            /**
             *
             * @type {Count}
             */
            count: null
        }
        this.genInfoRow=this.genInfoRow.bind(this);
    }

    componentDidMount() {
        if (typeof this.props.params.id !== 'undefined') {
            GetCount(this.props.params.id).then(count => {
                let tmpCount=new Count(count.data);
                this.setState({count: tmpCount});

                GetFlow(tmpCount.flowId).then(flow=>{
                    this.setState({flow: new Flow(flow.data)})

                    this.setState({allLoaded:true})
                });
            })
        }


    }

    genInfoRow(props) {
        let {t} = this.props;
        let {name, tooltip, inputValue, valueIfPresent} = props

        return (
            <dl className={"column col-12"}>
                <div className={"columns"} style={{paddingTop: "5px", borderBottom: "2px solid #D8D8D8"}}>
                    <dt className={"column col-6"}>
                        {t(name)}
                        {(typeof tooltip!=='undefined' && t(tooltip)!==null && t(tooltip)!=='') &&
                        <Tooltip title={t(tooltip)}>
                            <IconButton>
                                <InfoIcon/>

                            </IconButton>
                        </Tooltip>
                        }
                        :
                    </dt>

                    <dd className={"column col-6"}>
                        {typeof props.linkTo !== 'undefined' ? <Link to={props.linkTo}>
                                {typeof inputValue === 'undefined' ? '' : (typeof valueIfPresent !== 'undefined' ? valueIfPresent : inputValue)}
                            </Link> :
                            typeof inputValue === 'undefined' ? '' : (typeof valueIfPresent !== 'undefined' ? valueIfPresent : inputValue)
                        }

                    </dd>

                </div>
            </dl>)
    }

    render() {
        let {t} = this.props;
        /**
         *
         *  @type {Count}
         */
        let count = (typeof this.props.count !== 'undefined' ? this.props.count : this.state.count);

        return (
            <>

                
{/*                <button className="btn btn-success float-right" onClick={() => this.props.navigate("/count/edit/"+count.countId)} disabled={localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" || localStorage.getItem("user-role") === "Nasta.Recorder" ? false : true}>{t("common:button_edit")}</button>*/}

                <br/><br/>

                {this.state.allLoaded && <div>
                <this.genInfoRow name={'flowName'} tooltip={'tooltip_flowName'} inputValue={this.state.flow.flowName}/>
                <this.genInfoRow name={'flow_id'} tooltip={'tooltip_flow_id'} inputValue={count.flowId}/>
                <this.genInfoRow name={'countId'} tooltip={'tooltip_countId'} inputValue={count.countId}/>
                <this.genInfoRow name={'periodBeginTime'} tooltip={'tooltip_periodBeginTime'} inputValue={moment(count.periodBeginTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("DD.MM.YYYY HH:mm")}/>
                <this.genInfoRow name={'periodEndTime'} tooltip={'tooltip_periodEndTime'} inputValue={moment(count.periodEndTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("DD.MM.YYYY HH:mm")}/>
                <this.genInfoRow name={'countsOriginal'} tooltip={'tooltip_countsOriginal'} inputValue={count.countsOriginal}/>
                <this.genInfoRow name={'countsCorrected'} tooltip={'tooltip_countsCorrected'} inputValue={count.countsCorrected}/>

                <this.genInfoRow name={'calibrationCoefficient'} tooltip={'tooltip_calibrationCoefficient'} inputValue={this.state.count?.calibrationCoefficient?.toString().replace(".",",")}/>
                <this.genInfoRow name={'visits'} tooltip={'tooltip_visits'} inputValue={this.state.count.visits!==null?Math.round(this.state.count.visits).toString():""}/>
                <this.genInfoRow name={'description'} tooltip={'tooltip_description'} inputValue={count.description}/>

                <this.genInfoRow name={'createdBy'} tooltip={'tooltip_createdBy'} inputValue={count.createdBy}/>
                <this.genInfoRow name={'createdTime'} tooltip={'tooltip_createdTime'} inputValue={moment(count.createdTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("DD.MM.YYYY HH:mm")}/>
                <this.genInfoRow name={'modifiedBy'} tooltip={'tooltip_modifiedBy'} inputValue={count.modifiedBy}/>
                <this.genInfoRow name={'modifiedTime'} tooltip={'tooltip_modifiedTime'} inputValue={moment(count.modifiedTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("DD.MM.YYYY HH:mm")}/>
                </div>
                }
            </>
        )
    }
}

export default withRouter(ViewCount)