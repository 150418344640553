import { createSlice } from '@reduxjs/toolkit'

export const destinationsFilterSelector = createSlice({
  name: 'sitesFilterSelector',
  initialState: {
    isOpen: false,
    site: {
        isOpen: false,
        selectAll: false,
        notSpecified: false,
        planned: false,
        underConstruction: false,
        inUse: true,
        discarded: false,
    },
  },
  reducers: {
    //plus sign
    changeIsOpen: (state) => {
        state.isOpen = !state.isOpen;
    },
    changeSiteIsOpen: (state) => {
        state.site.isOpen = !state.site.isOpen;
    },
    changeSiteSelectAll: (state) => {
        let status = !state.site.selectAll;
        Object.entries(state.site).forEach(fe => {if(fe[0] !== 'isOpen'){ state.site[fe[0]] = status }else{state.site[fe[0]] = !status}});
    },
    changeSiteSelection: (state, action) => {
        state.site[action.payload] = !state.site[action.payload];
        let resultArray = [];
        let lengthOfSites = Object.keys(state.site).filter(key => key !== 'isOpen' && key !== 'selectAll').length;
        
        Object.keys(state.site)
            .filter(key => key !== 'isOpen' && key !== 'selectAll')
            .forEach(fk => {if(state.site[fk]){resultArray.push(state.site[fk])}});

        if(lengthOfSites === resultArray.length){
            state.site.selectAll = true;
        }else{
            state.site.selectAll = false;
        }
    },
  },
})

// Action creators are generated for each case reducer function
export const {changeIsOpen, changeSiteIsOpen, changeSiteSelectAll, changeSiteSelection} = destinationsFilterSelector.actions

export default destinationsFilterSelector.reducer