import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import './MainMenu.css';
import React from "react";

export default function MainMenu(props) {
    const {t}=useTranslation("mainMenu", "navbar");

    return (

        <ul className="menu menu-left">

            <li className="menu-item" onClick={()=>props.onSelectLink()}>
                <Link to={"/destinations/add"}>
                <div>
                    <i className="icon icon-location"></i> <span >{t("navbar:destinations_button")}</span>
                </div>
                </Link>
            </li>
            <li className="menu-item"  onClick={()=>props.onSelectLink()}>
                <Link to={"/sites/add"}>
                    <i className="icon icon-location"></i> <span>{t("navbar:sites_button")}</span>
                </Link>
            </li>

            <li className="menu-item" onClick={()=>props.onSelectLink()}>
                <Link to={"/flow/list"}>
                    <i className="icon icon-location"></i> {t("navbar:flow_button")}
                </Link>
            </li>

            <li className="menu-item" onClick={()=>props.onSelectLink()}>
                <Link to={"/countingRule/list"}>
                    <i className="icon icon-location"></i> {t("navbar:countingRule_button")}
                </Link>
            </li>

            <li className="menu-item" onClick={()=>props.onSelectLink()}>
                    <i className="icon icon-location"></i> {t("navbar:reports_button")}

            </li>

        </ul>
)

}