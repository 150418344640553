import React from "react";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import moment from "moment-timezone/moment-timezone-utils";
import {
    GetUserCreatedDestinations,
    GetSitesMinimumData,
    GetFlowsBySiteCount,
    GetCountingRulesForList,
    GetCountingsForList
} from "../utils/NastaApiClient";


import './ListPlace.css';
import SearchBox from "../widgets/SearchBox/SearchBox";
import {useDispatch, useStore} from "react-redux";
import {updateDestinationSelection} from "../mapViewOl/mapSelectionSelector";
import {default as MPagination} from "@mui/material/Pagination/Pagination";
import {getCodeText} from "../utils/CodeHelpers";
import {
    Stack,
    Paper,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Collapse,
    Box, IconButton
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UploadingNotification from "../widgets/UploadingNotification/UploadingNotification";
import Tooltip from "@mui/material/Tooltip";
import ReportProblemTwoToneIcon from "@mui/icons-material/ReportProblemTwoTone";

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const {t} = useTranslation('addPlace', 'common')
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const storeHelper = useStore();

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
            params={params}
            navigate={navigate}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            storeHelper={storeHelper}
            dispatch={dispatch}
        />
    );
};

class ListPlace extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paginationOffset:0,
            searchValue:null,
            searchResults:[],
            destinations: null,
            sites: null,
            flows:null,
            filteredIdList:null,
            loadingMapFilters:false,
            openedSiteIds:new Set(),
            openedCountingRuleIds: new Set(),
            countingRulesPerDestination:{},
            openedCountings:new Set(),
            countingsPerDestination:{}

        }
        this.allLoaded = this.allLoaded.bind(this);
        this.setPagination=this.setPagination.bind(this);
    }

    allLoaded() {

        let {
            destinations,
            sites,
            flows
        } = this.state;

        if (destinations!==null && sites !== null && flows!==null) {
            return true
        }

        return false
    }

  componentDidMount() {
        let tmpDestinations;


       GetUserCreatedDestinations().then(async destinations => {

            tmpDestinations = destinations.data.map(destination => {
                return destination.properties
            });
            tmpDestinations = tmpDestinations.sort((a, b) => {
                return a.destinationId > b.destinationId ? -1 : 1
            });

            
        let tmpCountingRules = {};
            await GetCountingRulesForList().then(countingRules=>{


                for (const countingRule of countingRules.data) {
                    if(!Array.isArray(tmpCountingRules[countingRule.destinationId])){
                        tmpCountingRules[countingRule.destinationId]=[];
                    }
                    tmpCountingRules[countingRule.destinationId].push(countingRule);
                }

            });

            let tmpCountings = {};
            await GetCountingsForList().then(countings=>{

                for (const counting of countings.data) {
                    if(!Array.isArray(tmpCountings[counting.countingRule.destinationId])){
                        tmpCountings[counting.countingRule.destinationId]=[];
                    }
                    tmpCountings[counting.countingRule.destinationId].push(counting);
                }
            });

            this.setState({destinations: tmpDestinations, countingRulesPerDestination:tmpCountingRules,countingsPerDestination:tmpCountings});
});

       GetSitesMinimumData().then(sites => {

            this.setState({sites: sites.data});
        })

        GetFlowsBySiteCount().then(flows=>{

            this.setState({flows: flows.data});
        })

        this.setState({filteredIdList:this.props.storeHelper.getState().mapSelectionSelector.selections.selectedDestinations})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(typeof this.props.params.page!=='undefined'){
            let page = parseInt(this.props.params.page);
            if(page-1!==this.state.paginationOffset){


                if(this.state.searchValue!==null && this.state.searchValue!==""){
                }else{

                    this.setState({paginationOffset:(page-1)})
                }
            }
        }
        if(this.state.loadingMapFilters===true && this.state.filteredIdList!==null){
            this.setState({loadingMapFilters:false});
        }
    }

    setPagination(pageNumber){

        let page=parseInt(pageNumber)

        let currentDestination="all";
        if(typeof this.props.params.id!=='undefined'){
            currentDestination=parseInt(this.props.params.id);
        }
        this.setState({paginationOffset: page-1}, ()=>{

            this.props.navigate("/destination/list/"+currentDestination+"/"+page)
        });
    }

    render() {

        let {t} = this.props;

        /**
         *
         * @type {Destination[]}
         */
        let destinations = this.state.destinations;
        /**
         *
         *
         * @type {Site[]}
         */
        let sites = this.state.sites;

        /**
         *
         * @type {Flow[]}
         */
        let flows = this.state.flows;


        let resultCount=0;
        for(let entry of this.props.searchParams.entries()){
            resultCount++;
        }

        if(resultCount>0 && this.state.filteredIdList===null && this.state.loadingMapFilters===false){
            this.setState({loadingMapFilters:true})
        }

        let includedList=destinations?.filter(destination=>{
            return this.state.filteredIdList?.includes(destination.destinationId);
        });


        let maskedDestinationList = [];

        if(destinations!==null){
            maskedDestinationList=includedList;
        }

        if(this.state.searchValue!==null && this.state.searchValue!==""){
            includedList=includedList.filter(destination=>{
                for (const searchResult of this.state.searchResults) {
                    if( searchResult.item.destinationId===destination.destinationId){
                        return true
                    }

                }
                return false;
            })
            maskedDestinationList=maskedDestinationList.filter(destination=>{

                for (const searchResult of this.state.searchResults) {
                    if( searchResult.item.destinationId===destination.destinationId){
                        return true
                    }

                }
                return false;
            });
            this.props.dispatch(updateDestinationSelection({list:maskedDestinationList.map(x=>x.destinationId), listView:true}))

        }
        maskedDestinationList = maskedDestinationList.slice(this.state.paginationOffset*50, (this.state.paginationOffset*50)+50);

        let currentPageMax=this.state.paginationOffset * 50+50<includedList?.length ? (this.state.searchResults.length!==0 && this.state.searchResults!==null?maskedDestinationList.length:this.state.paginationOffset * 50+50) :includedList?.length




        let maxPages=0;
        let uniques = [];
        if(this.state.searchResults.length !== 0){

            for (const searchResult of this.state.searchResults) {
                if(!uniques.some(u=>u.id===searchResult.id)){
                    uniques.push(searchResult)
                }
            }
            currentPageMax=uniques.length>this.state.paginationOffset*50+50?this.state.paginationOffset*50+50:uniques.length
            maxPages=Math.ceil(uniques.length/50)
        }else{
            maxPages=Math.ceil(includedList?.length/50)
        }


        if(typeof this.props.params.page!=='undefined'  &&  this.allLoaded()  ){
            if(parseInt(this.props.params.page)>maxPages && parseInt(this.props.params.page)!==1 && maxPages!==0){
                this.setPagination(1);
            }
        }
        return (
            <>

               <UploadingNotification showModal={this.allLoaded()===false || this.state.filteredIdList===null} />
                {this.allLoaded() === true &&
                    <>

                        <SearchBox
                            sortByAttr={"destinationId"}
                            titleAttrs={["destinationId","destinationName"]}
                            baseLinkUrl={"/destination/view"}
                            idAttrField={"destinationId"}
                            searchableFields={[
                            "destinationId",
                            "destinationName",
                            "destinationFunder",
                            "destinationOwner",
                            "destinationType",
                            "parkDistrict",
                            "regionalUnit",
                            "county",
                            "entity",
                            "landUseUnitName",
                            "customerServicePointName",
                            "municipality",
                            "createdBy",
                            "modifiedBy",
                            "description",
                            "created",
                            "modified",
                            "customerServiceName",
                            "customerServiceNumber",
                            "parkDistrictTypeCodeId",
                            "regionalUnitTypeCodeId",
                            "destinationTypeCodeId",
                            "destinationOwnerCodeId",
                            "destinationFunderCodeId",
                            "entityTypeCodeId",
                            "inUse"
                        ]}
                            indirectAttrFields={[
                                ["entityTypeCodeId", "entityCodeClassId", "entityTypeCodeId"],
                                ["destinationTypeCodeId", "destinationTypeClassCodeId", "destinationTypeCodeId"],
                                ["destinationOwnerCodeId", "destinationOwnerCodeClassId", "destinationOwnerCodeId"],
                                ["destinationFunderCodeId", "destinationFunderCodeClassId", "destinationFunderCodeId"],
                                ["parkDistrictTypeCodeId", "parkDistrictTypeCodeClassId", "parkDistrictTypeCodeId"],
                                ["regionalUnitTypeCodeId", "regionalUnitClassCodeId", "regionalUnitTypeCodeId"],
                                ["statusCodeId", "statusCodeClassId", "statusCodeId"],

                            ]}

                            customSearchRuleFields={[
                                ["inUse", (x)=>{
                                    if(x){
                                        return t("inUseResponseTrue");
                                    }else{
                                        return t("inUseResponseFalse");
                                    }
                                }],
                                ["county", (c)=>{


                                    let tmpList = c.map(x=>{
                                        return x+" "+ getCodeText(1005, x, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses);
                                    })

                                    let returnSentence="";
                                    for (const tmpListElement of tmpList) {
                                        returnSentence+=tmpListElement+" "
                                    }

                                    return returnSentence
                                }],
                                ["municipality", (c)=>{


                                    let tmpList = c.map(x=>{
                                        return x+" "+ getCodeText(280, x, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses);
                                    })

                                    let returnSentence="";
                                    for (const tmpListElement of tmpList) {
                                        returnSentence+=tmpListElement+" "
                                    }

                                    return returnSentence
                                }],


                            ]}
                            dateAttrFields={["created", "modified"]}
                           items={( this.state.filteredIdList!==null && this.state.filteredIdList.length>0)?destinations.filter(destination=>
                               this.state.filteredIdList.includes(destination.destinationId)):destinations
                        }
                           onResultsChanged={(results)=>{
                               if(results.length<this.state.paginationOffset*50){
                                   this.setPagination(1);
                               }
                               this.setState({searchResults:results})
                                }
                            }
                           onSearchValueChanged={(searchValue)=>{
                               this.setState({searchValue:searchValue})
                           }}
                        />

                        <br/>
                        <br/>

                        
                        <Stack alignItems={"center"} spacing={2} style={{marginBottom:"2vh"}}>

                            <p style={{textAlign: "center",marginBottom:"-2vh"}}>
                                {((this.state.paginationOffset) * 50)+(currentPageMax>0?1:0)} - {currentPageMax} /
                                {this.state.searchResults.length!==0 && this.state.searchResults!==null ?this.state.searchResults.map(x=>x.id).filter((x,y,z)=>z.indexOf(x)===y).length:includedList.length}
                            </p>
                            <MPagination
                                page={(this.state.paginationOffset+1>maxPages?maxPages:this.state.paginationOffset+1)}
                                onChange={(e,val)=>{
                                    this.setPagination(val);
                                }}
                                count={maxPages}
                                boundaryCount={1}
                                siblingCount={4}
                                color={"primary"} />
                        </Stack>

                        <TableContainer component={Paper}>

                            <Table sx={{}} aria-label={t("flow_list")}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <b>
                                            {t('destination_id')}
                                            </b>
                                        </TableCell>
                                        <TableCell>
                                            <b>
                                            {t('destination_listing_header')}
                                            </b>
                                        </TableCell>
                                        <TableCell>
                                            <b>
                                            {t('related_sites')}
                                            </b>
                                        </TableCell>
                                        <TableCell>
                                            <b>
                                            {t('counting_rules_name')}
                                            </b>
                                        </TableCell>
                                        <TableCell>
                                            <b>
                                                {t('countings_name')}
                                            </b>
                                        </TableCell>
                                    </TableRow>

                                </TableHead>

                                <TableBody>

                                    {
                                        maskedDestinationList.filter(dest=>{
                                            if(this.state.filteredIdList===null|| this.state.filteredIdList.includes(dest.destinationId)){
                                                return true
                                            }
                                        }).map((destination) => {

                                            let someDropdownIsOpen = ["openedSiteIds", "openedCountingRuleIds", "openedCountings"].some( x => this.state[x].has(destination.destinationId) )



                                            let sitesWithThisDestination=[];
                                            sitesWithThisDestination=sites.filter(site=>{

                                                return site.siteDestination?.some(x=>x.destinationId===destination.destinationId)
                                            })

                                            return <>

                                                <TableRow style={{borderBottom: (someDropdownIsOpen?'2px solid black':''),borderTop:(someDropdownIsOpen?'2px solid black':'')}} className={someDropdownIsOpen?'sub-headers':''}>
                                                    <TableCell style={{fontWeight:  (someDropdownIsOpen?'bold':'')}}>
                                                        {destination.destinationId}
                                                    </TableCell>

                                                    <TableCell style={{fontWeight:  (someDropdownIsOpen?'bold':'')}}><Link
                                                        to={"/destination/view/" + destination.destinationId}>{destination.destinationName}</Link></TableCell>
                                                    <TableCell className={" c-hand"} onClick={()=>{
                                                        let tmpIds=this.state.openedSiteIds;
                                                        if(tmpIds.has(destination.destinationId)){
                                                            tmpIds.delete(destination.destinationId);
                                                        }else{
                                                            if(sitesWithThisDestination.length>0){
                                                                if(this.state.openedCountingRuleIds.has(destination.destinationId)){
                                                                    this.state.openedCountingRuleIds.delete(destination.destinationId)
                                                                }
                                                                if(this.state.openedCountings.has(destination.destinationId)){
                                                                    this.state.openedCountings.delete(destination.destinationId);
                                                                }
                                                                tmpIds.add(destination.destinationId);
                                                            }

                                                        }

                                                        this.setState({openedSiteIds: tmpIds});
                                                    }}>

                                                         {sitesWithThisDestination.length>0 && (this.state.openedSiteIds.has(destination.destinationId)
                                                        ?<><b>{t('sites_name')}</b> ({sitesWithThisDestination.length}) <KeyboardArrowUpIcon/></>:<>{t('sites_name')} ({sitesWithThisDestination.length}) <KeyboardArrowDownIcon/></>)}
                                                    </TableCell>
                                                    <TableCell className={" c-hand"} onClick={()=>{
                                                        let tmpIds=this.state.openedCountingRuleIds;
                                                        if(tmpIds.has(destination.destinationId)){
                                                            tmpIds.delete(destination.destinationId);
                                                        }else{
                                                            if(this.state.countingRulesPerDestination[destination.destinationId]?.length>0){

                                                                if(this.state.openedSiteIds.has(destination.destinationId)){
                                                                    this.state.openedSiteIds.delete(destination.destinationId);
                                                                }

                                                                if(this.state.openedCountings.has(destination.destinationId)){
                                                                    this.state.openedCountings.delete(destination.destinationId);
                                                                }
                                                                tmpIds.add(destination.destinationId);
                                                            }

                                                        }

                                                        this.setState({openedCountingRuleIds: tmpIds});
                                                    }}>
                                                          {this.state.countingRulesPerDestination[destination.destinationId]?.length>0 && (this.state.openedCountingRuleIds.has(destination.destinationId)
                                                        ?<><b>{t('counting_rules_name')}</b>  ({typeof this.state.countingRulesPerDestination[destination.destinationId] !== 'undefined' ? this.state.countingRulesPerDestination[destination.destinationId]?.length: 0})<KeyboardArrowUpIcon/></>:<>{t('counting_rules_name')} ({typeof this.state.countingRulesPerDestination[destination.destinationId] !== 'undefined' ? this.state.countingRulesPerDestination[destination.destinationId]?.length: 0})<KeyboardArrowDownIcon/></>)}
                                                    </TableCell>
                                                    <TableCell className={" c-hand"} onClick={()=>{
                                                        let tmpIds=this.state.openedCountings;
                                                        if(tmpIds.has(destination.destinationId)){
                                                            tmpIds.delete(destination.destinationId);
                                                        }else{
                                                            if(this.state.countingsPerDestination[destination.destinationId]?.length>0){

                                                                if(this.state.openedSiteIds.has(destination.destinationId)){
                                                                    this.state.openedSiteIds.delete(destination.destinationId);
                                                                }
                                                                if(this.state.openedCountingRuleIds.has(destination.destinationId)){
                                                                    this.state.openedCountingRuleIds.delete(destination.destinationId)
                                                                }
                                                                tmpIds.add(destination.destinationId);
                                                            }

                                                        }

                                                        this.setState({openedCountings: tmpIds});
                                                    }}>
                                                          {this.state.countingsPerDestination[destination.destinationId]?.length>0 && (this.state.openedCountings.has(destination.destinationId)
                                                        ?<><b>{t('countings_name')}</b> ({typeof  this.state.countingsPerDestination[destination.destinationId] !== 'undefined'? this.state.countingsPerDestination[destination.destinationId]?.length:0})<KeyboardArrowUpIcon/></>:<>{t('countings_name')} ({typeof  this.state.countingsPerDestination[destination.destinationId] !== 'undefined'? this.state.countingsPerDestination[destination.destinationId]?.length:0})<KeyboardArrowDownIcon/></>)}
                                                    </TableCell>
                                                </TableRow>


                                                <TableRow>

                                                    {this.state.openedSiteIds.has(destination.destinationId) &&
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>

                                                <Collapse in={this.state.openedSiteIds.has(destination.destinationId)} timeout="auto" unmountOnExit>
                                                    <Box sx={{margin:1}}>
                                                    <Table>

                                                    <TableRow className={"sub-headers"}>
                                                        <TableCell  style={{fontWeight: "500"}}><b>{t('flow_id')}</b></TableCell>
                                                        <TableCell style={{fontWeight: "500"}}><b>{t('site_name')}</b></TableCell>
                                                        <TableCell  style={{fontWeight: "500"}}><b>{t('related_flows')}</b></TableCell>
                                                    </TableRow>

                                                    {sitesWithThisDestination.sort((a,b)=>parseInt(a.siteId)<parseInt(b.siteId)?1:-1).map(x=>{return<>
                                                        <TableRow className={"sub-headers"}>
                                                            <TableCell align={"left"}>
                                                                <span>

                                                                    {x.siteId}
                                                                </span>
                                                            </TableCell>

                                                            <TableCell align={"left"}>
                                                                <span>
                                                                    <Link to={"/site/view/"+x.siteId}>
                                                                        {x.siteName}
                                                                    </Link>
                                                                </span>
                                                            </TableCell>
                                                            <TableCell align={"left"}>
                                                                <span>
                                                                    <Link to={"/flow/list/"+x.siteId}>
                                                                        {t('related_flows')} ({flows.find(f=>f.siteId===x.siteId).flowCount})
                                                                    </Link>
                                                                </span>
                                                            </TableCell>
                                                            <TableCell>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>

                                                    })}

                                                    </Table>

                                                    </Box>
                                                </Collapse>

                                                    </TableCell>
                                                    }


                                                    {this.state.openedCountingRuleIds.has(destination.destinationId) &&

                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                        <Collapse in={this.state.openedCountingRuleIds.has(destination.destinationId)} timeout="auto" unmountOnExit>
                                                            <Box sx={{margin:1}}>
                                                                <Table>
                                                                    <TableRow className={"sub-headers"}>
                                                                        <TableCell align={"left"} style={{fontWeight: "500"}}><b>{t('listCountingRule:columnHeader_countingRule_id')}</b></TableCell>
                                                                        <TableCell align={"left"}  style={{fontWeight: "500"}}><b>{t('listCountingRule:columnHeader_countingRule_name')}</b></TableCell>
                                                                        <TableCell align={"left"} style={{fontWeight: "500"}}><b>{t('listCountingRule:columnHeader_countingRule_timeline')}</b></TableCell>
                                                                        <TableCell align={"left"} style={{fontWeight: "500"}}><b>{t('listCountingRule:columnHeader_countingRule_frequency')}</b></TableCell>
                                                                        <TableCell align={"left"} style={{fontWeight: "500"}}><b>{t('listCountingRule:columnItem_Valid')}</b></TableCell>
                                                                    </TableRow>
                                                                    {this.state.countingRulesPerDestination[destination.destinationId]?.sort((a,b)=>parseInt(a.countingRuleId)<parseInt(b.countingRuleId)?1:-1).map(x=>{return<>
                                                                        <TableRow className={"sub-headers"}>
                                                                            <TableCell align={"left"}>


                                                                                    {x.countingRuleId}

                                                                            </TableCell>

                                                                            <TableCell align={"left"}>

                                                                                    <Link to={"/countingRule/view/"+x.countingRuleId}>
                                                                                        {x.rulename}
                                                                                    </Link>

                                                                            </TableCell>

                                                                            <TableCell align={"left"}>


                                                                                   {x.ruleBegins} - {x.ruleEnds}


                                                                            </TableCell>

                                                                            <TableCell align={"left"}>

                                                                                    {getCodeText(x.countingFrequency.codeClassId, x.countingFrequency.codeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses)}

                                                                                </TableCell>
                                                                            <TableCell align={"left"}>

                                                                                    {x.inUse ?  t("listCountingRule:columnItem_Valid") : t("listCountingRule:columnItem_Invalid")}

                                                                                </TableCell>

                                                                        </TableRow>
                                                                    </>

                                                                    })}
                                                                </Table>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                    }
                                                    {this.state.openedCountings.has(destination.destinationId) &&

                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                            <Collapse in={this.state.openedCountings.has(destination.destinationId)} timeout="auto" unmountOnExit>
                                                                <Box sx={{margin:1}}>
                                                                    <Table>
                                                                        <TableRow className={"sub-headers"}>
                                                                            <TableCell style={{fontWeight:"500"}} align={"left"}>
                                                                                <b>
                                                                                {t("listCounting:counting_id")}
                                                                                </b>
                                                                            </TableCell>
                                                                            <TableCell style={{fontWeight:"500"}} align={"left"}>
                                                                                <b>
                                                                                {t("listCounting:counting_destination_name")}
                                                                                </b>
                                                                            </TableCell>
                                                                            <TableCell style={{fontWeight:"500"}} align={"left"}>
                                                                                <b>
                                                                                {t("listCounting:counting_userType")}
                                                                                </b>
                                                                            </TableCell>
                                                                            <TableCell style={{fontWeight:"500"}} align={"left"}>
                                                                                <b>
                                                                                {t("listCounting:counting_StartTime")}
                                                                                </b>
                                                                            </TableCell>
                                                                            <TableCell style={{fontWeight:"500"}} align={"left"}>
                                                                                <b>
                                                                                {t("listCounting:counting_EndTime")}
                                                                                </b>
                                                                            </TableCell>
                                                                            <TableCell style={{fontWeight:"500"}} align={"left"}>
                                                                                <b>
                                                                                {t("listCounting:counting_visits")}
                                                                                </b>
                                                                            </TableCell>
                                                                            <TableCell style={{fontWeight:"500"}} align={"left"}>
                                                                                <b>
                                                                                {t("listCounting:counting_isReady")}
                                                                                </b>
                                                                            </TableCell>

                                                                        </TableRow>
                                                                        {this.state.countingsPerDestination[destination.destinationId]?.sort((a,b)=>parseInt(a.countingId)<parseInt(b.countingId)?1:-1).map(x=>{return<>
                                                                            <TableRow className={"sub-headers"}>
                                                                                <TableCell>
                                                                                    <span>
                                                                                        {typeof x.countingId !== 'undefined' ?
                                                                                            <Link to={"/counting/view/" + x.countingId}>
                                                                                                <td className="c-hand"> {x.countingId}</td>
                                                                                            </Link> : <td></td>
                                                                                        }
                                                                                    </span>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <span>
                                                                                        {x.countingRule.destination.destinationName}
                                                                                    </span>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <span>
                                                                                        {x.countingRule.userType !== undefined && x.countingRule.userType !== null ? x.countingRule.userType.languages[x.countingRule.userType.languages.findIndex(i => i.languageName === this.props.t('common:GMLLanguage'))].text : ''}
                                                                                    </span>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <span>
                                                                                        {moment(x.countingBeginTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD.MM.YYYY HH:mm')}
                                                                                    </span>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <span>
                                                                                        {moment(x.countingEndTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD.MM.YYYY HH:mm')}
                                                                                    </span>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <span>
                                                                                        <div className="visits-with-errors">
                                                                                            {x.error === true && x.visits === null ?
                                                                                                <Tooltip title={t('listCounting:counting_errors_in_execution')}><IconButton><ReportProblemTwoToneIcon/></IconButton></Tooltip>  :
                                                                                                x.visits === 0 ? 0 : parseFloat(x.visits).toFixed(2).replace('.', ',')} {x.error === true && x.visits !== null && <Tooltip title={t('listCounting:counting_errors_in_execution')}><IconButton onClick={() => this.openErrorDialog(x)}><ReportProblemTwoToneIcon/></IconButton></Tooltip>}
                                                                                        </div>
                                                                                    </span>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <span>
                                                                                    {x.ready === true ? t("listCounting:counting_isReady_true") : t("listCounting:counting_isReady_false")}
                                                                                    </span>
                                                                                    </TableCell>

                                                                            </TableRow>
                                                                        </>

                                                                        })}
                                                                    </Table>
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    }

                                                </TableRow>




                                            </>


                                        })

                                    }
                                </TableBody>

                            </Table>
                        </TableContainer>

                        <Stack alignItems={"center"} spacing={2} style={{marginBottom:"2vh"}}>

                            <p style={{textAlign: "center",marginBottom:"-2vh"}}>
                                {((this.state.paginationOffset) * 50)+(currentPageMax>0?1:0)} - {currentPageMax} /
                                {this.state.searchResults.length!==0 && this.state.searchResults!==null ?this.state.searchResults.map(x=>x.id).filter((x,y,z)=>z.indexOf(x)===y).length:includedList.length}
                            </p>
                            <MPagination
                                page={(this.state.paginationOffset+1>maxPages?maxPages:this.state.paginationOffset+1)}
                                onChange={(e,val)=>{
                                    this.setPagination(val);
                                }}
                                count={maxPages}
                                boundaryCount={1}
                                siblingCount={4}
                                color={"primary"} />
                        </Stack>


                    </>
                }
            </>
        )
    }
}

export default withRouter(ListPlace)