import {UserType} from "../UserType";
import {Destination} from "../Destination/Destination";

export class CountingRule{

    _countingFrequency;
    _countingRuleFlows;
    _countingRuleGuid;
    _countingRuleId;
    _coveragePercent;
    _createdBy;
    _createdTime;
    _deputyName;
    _description;
    _destination;
    _destinationId;
    _inUse;
    _modifiedBy;
    _modifiedTime;
    _nonce;
    _responsibleName;
    _ruleBegins;
    _ruleEnds;
    _ruleName;
    _userType;
    constructor(countingFrequency,
                countingRuleFlows,
                countingRuleGuid,
                countingRuleId,
                coveragePercent,
                createdBy,
                createdTime,
                deputyName,
                description,
                destination,
                destinationId,
                inUse,
                modifiedBy,
                modifiedTime,
                nonce,
                responsibleName,
                ruleBegins,
                ruleEnds,
                ruleName,
                userType) {


        if(typeof countingFrequency["countingFrequency"] !== 'undefined') {
            this._countingFrequency = countingFrequency["countingFrequency"];
            this._countingRuleFlows = countingFrequency["countingRuleFlows"];
            this._countingRuleGuid = countingFrequency["countingRuleGuid"];
            this._countingRuleId = countingFrequency["countingRuleId"];
            this._coveragePercent = countingFrequency["coveragePercent"];
            this._createdBy = countingFrequency["createdBy"];
            this._createdTime = countingFrequency["createdTime"];
            this._deputyName = countingFrequency["deputyName"];
            this._description = countingFrequency["description"];
            this._destination =(typeof countingFrequency["destination"] !=='undefined' && countingFrequency["destination"]!==null)? new Destination(countingFrequency["destination"]):null;
            this._destinationId = countingFrequency["destinationId"];
            this._inUse = countingFrequency["inUse"];
            this._modifiedBy = countingFrequency["modifiedBy"];
            this._modifiedTime = countingFrequency["modifiedTime"];
            this._nonce = countingFrequency["nonce"];
            this._responsibleName = countingFrequency["responsibleName"];
            this._ruleBegins = countingFrequency["ruleBegins"];
            this._ruleEnds = countingFrequency["ruleEnds"];
            this._ruleName = countingFrequency["ruleName"];
            this._userType =(typeof countingFrequency["userType"]!=='undefined' && countingFrequency["userType"]!==null )? new UserType(countingFrequency["userType"]):null;
        }else{
            this._countingFrequency = countingFrequency;
            this._countingRuleFlows = countingRuleFlows;
            this._countingRuleGuid = countingRuleGuid;
            this._countingRuleId = countingRuleId;
            this._coveragePercent = coveragePercent;
            this._createdBy = createdBy;
            this._createdTime = createdTime;
            this._deputyName = deputyName;
            this._description = description;
            this._destination = destination;
            this._destinationId = destinationId;
            this._inUse = inUse;
            this._modifiedBy = modifiedBy;
            this._modifiedTime = modifiedTime;
            this._nonce = nonce;
            this._responsibleName = responsibleName;
            this._ruleBegins = ruleBegins;
            this._ruleEnds = ruleEnds;
            this._ruleName = ruleName;
            this._userType = userType;
        }
    }

    get countingFrequency() {
        return this._countingFrequency;
    }

    set countingFrequency(value) {
        this._countingFrequency = value;
    }

    get countingRuleFlows() {
        return this._countingRuleFlows;
    }

    set countingRuleFlows(value) {
        this._countingRuleFlows = value;
    }

    get countingRuleGuid() {
        return this._countingRuleGuid;
    }

    set countingRuleGuid(value) {
        this._countingRuleGuid = value;
    }

    get countingRuleId() {
        return this._countingRuleId;
    }

    set countingRuleId(value) {
        this._countingRuleId = value;
    }

    get coveragePercent() {
        return this._coveragePercent;
    }

    set coveragePercent(value) {
        this._coveragePercent = value;
    }

    get createdBy() {
        return this._createdBy;
    }

    set createdBy(value) {
        this._createdBy = value;
    }

    get createdTime() {
        return this._createdTime;
    }

    set createdTime(value) {
        this._createdTime = value;
    }

    get deputyName() {
        return this._deputyName;
    }

    set deputyName(value) {
        this._deputyName = value;
    }

    get description() {
        return this._description;
    }

    set description(value) {
        this._description = value;
    }

    get destination() {
        return this._destination;
    }

    set destination(value) {
        this._destination = value;
    }

    get destinationId() {
        return this._destinationId;
    }

    set destinationId(value) {
        this._destinationId = value;
    }

    get inUse() {
        return this._inUse;
    }

    set inUse(value) {
        this._inUse = value;
    }

    get modifiedBy() {
        return this._modifiedBy;
    }

    set modifiedBy(value) {
        this._modifiedBy = value;
    }

    get modifiedTime() {
        return this._modifiedTime;
    }

    set modifiedTime(value) {
        this._modifiedTime = value;
    }

    get nonce() {
        return this._nonce;
    }

    set nonce(value) {
        this._nonce = value;
    }

    get responsibleName() {
        return this._responsibleName;
    }

    set responsibleName(value) {
        this._responsibleName = value;
    }

    get ruleBegins() {
        return this._ruleBegins;
    }

    set ruleBegins(value) {
        this._ruleBegins = value;
    }

    get ruleEnds() {
        return this._ruleEnds;
    }

    set ruleEnds(value) {
        this._ruleEnds = value;
    }

    get ruleName() {
        return this._ruleName;
    }

    set ruleName(value) {
        this._ruleName = value;
    }

    get userType() {
        return this._userType;
    }

    set userType(value) {
        this._userType = value;
    }

}