import React from "react";
import "./Footer.css";
import {withTranslation} from "react-i18next";

class Footer extends React.Component{
    constructor(props) {
        super(props);
        this.state={
           }
    }


    render(){
        let {t}=this.props;
        return (
            <>
            <footer>
                <div className="footer">
                    <div className="footer-version">Version: {process.env.REACT_APP_VERSION_INFO}</div>
                    <div className="footer-link">© <a target="_blank" href="https://www.maanmittauslaitos.fi/avoindata-lisenssi-cc40">Maanmittauslaitos</a> contributors</div>
                </div>
            </footer>
            </>
        )
    }
}

export default withTranslation("footer")(Footer)