import React from "react";
import {useTranslation, withTranslation} from "react-i18next";
import axios from "axios";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getCodeText} from '../utils/CodeHelpers'
import { useSelector, useDispatch, useStore} from 'react-redux'
import MPagination from "@mui/material/Pagination/Pagination"
import { ClimbingBoxLoader } from "react-spinners";
import './ListCounting.css';
import MultiChoiceSearchDropdown from "../widgets/MultiChoiceSearchDropdown/MultiChoiceSearchDropdown";
import ReportProblemTwoToneIcon from '@mui/icons-material/ReportProblemTwoTone';
import Tooltip from '@mui/material/Tooltip';

import {GetCountingsByDestinationId, GetCodeClasses, GetDestinations} from '../utils/NastaApiClient';
import {useMsal} from "@azure/msal-react";
import {AxiosError} from "axios";
import SearchBox from "../widgets/SearchBox/SearchBox";
import * as codesSelector from '../mapViewOl/codesSelector';
import {Input, Stack, Paper, TableContainer, Table, TableRow, TableHead, TableCell, TableBody, IconButton, Modal, Box, Button} from "@mui/material";
import * as countingDropdownDataSelector from './CountingDropdownDataSelector';
import CountingErrorDialog from "../widgets/CountingErrorDialog/CountingErrorDialog";
import UploadingNotification from "../widgets/UploadingNotification/UploadingNotification";
import moment from "moment-timezone";
import 'moment/locale/fi'
import { ReactComponent as AlertTriangle } from '../icons/alert-triangle_fill.svg';


const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const storeHelper = useStore();
    const dispatch = useDispatch();
    const codes = useSelector((state) => state.codes);
    const {t} = useTranslation('listCounting', 'common')

    return (
        <WrappedComponent
            t={t}
            {...props}
            params={params}

            {...{instance, /* other injected props */}}
            navigate={navigate}
            storeHelper={storeHelper}
            dispatch={dispatch}
            codes={codes}
        />
    );
};

class ListCounting extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paginationOffset:0,
            countings: [],
            searchResults:[],
            filteredList:[],
            countingsLoaded:false,
            pagedRuleList:[],
            searchValue:null,
            destinationSelectionDone: false,
            errorDialogOpen: false,
            currentCountingError: null
        }
        this.GetDestinationSelectionList = this.GetDestinationSelectionList.bind(this);
    }

    /**
     *
     * @returns {boolean}
     */
    allLoaded(){
        let {
            flowsLoaded,
            sitesLoaded,
            countsLoaded
        } = this.state;
        return (flowsLoaded && sitesLoaded && countsLoaded);
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {

        if(typeof this.props.params.page!=='undefined'){
            let page=parseInt(this.props.params.page);
            if(page-1!==this.state.paginationOffset){
                this.setState({paginationOffset:page-1});
            }
        }

        if(typeof this.props.addButtonPressed !== 'undefined'){
            if(this.props.addButtonPressed===true){
                this.props.navigate("/counting/add")
            }
        }

        if(typeof this.props.removeButtonPressed !== 'undefined'){
            if(this.props.removeButtonPressed===true && this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination !== undefined && Object.keys(this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination).length > 0){
                this.props.navigate("/counting/remove/"+this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination.destinationId);
            }
        }

        if(typeof this.props.markReadyButtonPressed !== 'undefined'){
            if(this.props.markReadyButtonPressed===true && this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination !== undefined && Object.keys(this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination).length > 0){
                this.props.navigate("/counting/markReady/"+this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination.destinationId);
            }
        }
    }

    async componentDidMount(){

        try
        {
            let response = await GetDestinations();
            if(response instanceof AxiosError){
                this.setState({error: response});
                this.setState({destinationsLoaded: false});
            }else{
                if(response.length > 0){
                    this.props.dispatch(countingDropdownDataSelector.changeListViewDestinationList(response.sort((a,b) => b.destinationId - a.destinationId)));
                    this.setState({destinationsLoaded: true});
                }
            }
        }
        catch(error){
            console.log(error);
        }

        if(this.props.params.id !== undefined && this.props.params.id !== null){
            this.props.dispatch(countingDropdownDataSelector.changeListViewDestination(this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestinations.find(f => parseInt(f.destinationId) ===  parseInt(this.props.params.id))));
        }

        try {
            if(!this.props.storeHelper.getState().codesSelector.codes.codeClassesLoaded){
                let response = await GetCodeClasses();

                if (response instanceof AxiosError) {
                    this.setState({error: response});
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(false));
                } else {
                    this.props.dispatch(codesSelector.fillCodeClasses(response));
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(true));
                }
            }
        } catch (error) {
            console.log(error);
        }

        if(this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination !== undefined && this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination !== null
            && Object.keys(this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination).length > 0){
            
                try {
                    this.setState({countingsLoaded: false});
                    let response = await GetCountingsByDestinationId(this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination.destinationId);
        
                    if (response instanceof AxiosError) {
                        this.setState({error: response, countingsLoaded: true, destinationSelectionDone: false});
                    } else {
                        this.setState({countings: response.data, countingsLoaded: true, destinationSelectionDone: true});
                    }
                    
                } catch (error) {
                    console.log(error);
                }
        }
    }

    setPagination(pageNumber){
        let page = parseInt(pageNumber)
        this.setState({paginationOffset: page}, ()=>{
            this.props.navigate("/counting/list/"+this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination.destinationId+"/"+page);
        });

    }


    GetDestinationSelectionList (props) {

        let destinationSelection = [];

        if (this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestinations.length > 0) {
            if (typeof this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestinations !== 'undefined') {
                destinationSelection = this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestinations.map(x => {
                    {
                        return {id: x.destinationId,
                            name: x.destinationName,
                            title: x.destinationId.toString(),
                            icon: "chip"}
                    }
                });
            }
        }

        let selectedDestination = [];
        if(this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination !== undefined && this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination !== null){
            var viewedDestination = destinationSelection.find(f => f.id === this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination.destinationId);
            if(viewedDestination !== undefined){
                selectedDestination.push(viewedDestination);
            }
        }

            return <MultiChoiceSearchDropdown
                required={true}
                maxChoices={1}
                active={this.state.viewMode === 'view' ? false : true}
                minChoices={1}
                title={this.props.t('addCounting:counting_destination')}
                tooltip={this.props.t('addCounting:counting_destination_tooltip')}
                placeholder={this.props.t('addCounting:counting_destination_placeholder')}
                inputData={destinationSelection}
                validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                choices={selectedDestination}
                onChoicesChange={async (choicesList) => {
                    if (choicesList.length > 0) {
                        this.props.dispatch(countingDropdownDataSelector.changeListViewDestination(this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestinations.find(f => parseInt(f.destinationId) ===  parseInt(choicesList[0].id))));

                        try {
            
                            this.setState({countingsLoaded: false});
                            let response = await GetCountingsByDestinationId(choicesList[0].id);
                
                            if (response instanceof AxiosError) {
                                this.setState({error: response, countingsLoaded: true, destinationSelectionDone: false});
                            } else {
                                this.setState({countings: response.data, countingsLoaded: true, destinationSelectionDone: true});
                            }
                            
                        } catch (error) {
                            console.log(error);
                        }

                        this.setState({pageUpdated: true});
                    }else{
                        this.props.dispatch(countingDropdownDataSelector.clearListView());
                        this.setState({pageUpdated: true, countings: [], destinationSelectionDone: false});
                    }
                }}
                onValidityChanged={()=>{}}
                choiceAttrsShown={['id', 'name']}

            />
        
    }

    openErrorDialog = (counting) => {
        this.setState({ 
          errorDialogOpen: true,
          currentCountingError: counting
        });
      }

    render() {
        let {t} = this.props;

        let currentCountings = [];
        let currentLength = 0;
        currentCountings = this.state.countings.length > 0 && this.state.countings.sort((a,b) => b.countingId - a.countingId).slice(this.state.paginationOffset * 50, (this.state.paginationOffset * 50) + 50);
        currentLength = this.state.countings.length



        if(this.state.filteredList.length > 0){
            currentCountings = this.state.filteredList.length > 0 && this.state.filteredList.map(m => m.item).sort((a,b) => b.countingId - a.countingId).slice(this.state.paginationOffset * 50, (this.state.paginationOffset * 50) + 50);
            currentLength = this.state.filteredList.length
        }

        return (


            <> 
                <UploadingNotification showModal={currentCountings.length===0} />
            
                <this.GetDestinationSelectionList/>
                    <Stack alignItems={"center"} spacing={2} >
                        <p style={{textAlign: "center",marginBottom:"-2vh"}}>
                            {((this.state.paginationOffset) * 50)+1}
                            - {(this.state.paginationOffset) * 50 + 50 < currentCountings.length ? (this.state.paginationOffset) * 50 + 50 : currentCountings.length} /
                            {Math.ceil(currentLength)}
                        </p>
                        <MPagination
                            page={this.state.paginationOffset+1}
                            onChange={(e,val)=>{
                                this.setPagination(val);
                            }}
                            count={Math.ceil(currentLength/50)}
                            boundaryCount={1}
                            siblingCount={4}
                            color={"primary"} />
                    </Stack>

                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label={t("flow_list")}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t("listCounting:counting_id")}
                                </TableCell>
                                <TableCell>
                                    {t("listCounting:counting_destination_name")}
                                </TableCell>
                                <TableCell>
                                    {t("listCounting:counting_userType")}
                                </TableCell>
                                <TableCell>
                                    {t("listCounting:counting_StartTime")}
                                </TableCell>
                                <TableCell>
                                    {t("listCounting:counting_EndTime")}
                                </TableCell>
                                <TableCell>
                                    {t("listCounting:counting_visits")}
                                </TableCell>
                                <TableCell>
                                    {t("listCounting:counting_isReady")}
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {currentCountings.length > 0 && currentCountings.map(counting => {
                                return <>
                                    <TableRow key={counting.countingId}>
                                        <TableCell>
                                            <b>
                                            {typeof counting.countingId !== 'undefined' ?
                                                <Link to={"/counting/view/" + counting.countingId}>
                                                    <td className="c-hand"> {counting.countingId}</td>
                                                </Link> : <td></td>
                                            }
                                            </b>
                                        </TableCell>
                                        <TableCell>
                                            <b>
                                            {counting.countingRule.destination.destinationName}
                                            </b>
                                        </TableCell>
                                        <TableCell>
                                            <b>
                                            {counting.countingRule.userType !== undefined && counting.countingRule.userType !== null ? counting.countingRule.userType.languages[counting.countingRule.userType.languages.findIndex(i => i.languageName === this.props.t('common:GMLLanguage'))].text : ''}
                                            </b>
                                        </TableCell>
                                        <TableCell>
                                            <b>
                                            {moment(counting.countingBeginTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD.MM.YYYY HH:mm')}
                                            </b>
                                        </TableCell>
                                        <TableCell>
                                            <b>
                                            {moment(counting.countingEndTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD.MM.YYYY HH:mm')}    
                                            </b>
                                        </TableCell>
                                        <TableCell>
                                            <b>
                                            <div className="visits-with-errors">
                                                {counting.error === true && counting.visits === null ? 
                                                            <Tooltip title={t('listCounting:counting_errors_in_execution')}><IconButton onClick={() => this.openErrorDialog(counting)}><AlertTriangle className="dialog-warning-color"/></IconButton></Tooltip>  : 
                                                            counting.visits === 0 ? 0 : parseFloat(counting.visits).toFixed(2).replace('.', ',')} {counting.error === true && counting.visits !== null && <Tooltip title={t('listCounting:counting_errors_in_execution')}><IconButton onClick={() => this.openErrorDialog(counting)}><AlertTriangle className="dialog-warning-color"/></IconButton></Tooltip>}
                                            </div>
                                            </b>
                                        </TableCell>
                                        <TableCell>
                                            {counting.ready === true ? <b>{t("listCounting:counting_isReady_true")}</b> : <b>{t("listCounting:counting_isReady_false")}</b>}
                                        </TableCell>
                                    </TableRow>
                                </>
                            })}
                        </TableBody>

                    </Table>
                </TableContainer>

                <CountingErrorDialog 
                    showModal={this.state.errorDialogOpen}
                    title={"title"}
                    counting={this.state.currentCountingError}    
                    onModalCancel={()=>this.setState({errorDialogOpen:false})}
                    onModalContinue={()=>this.setState({errorDialogOpen:false})}
                    />

                    {this.state.countingsLoaded === true || this.state.destinationSelectionDone === false ? '': <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width:'100%'}}><ClimbingBoxLoader/></div> }
            </>
        )
    }
}

export default withRouter(ListCounting);